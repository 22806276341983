import { useDispatch } from "react-redux";
import { XMLParser } from "fast-xml-parser";
import {
  setExtractedData,
  setExtractedPdfData,
  setExtractedPdfNplData,
  setIsPdfLoading
} from "../../store/reducer/searchSlice";
import { useEffect, useState } from "react";

const XMLDataExtractor = ({ files }) => {
  const dispatch = useDispatch();
  const [processedFiles, setProcessedFiles] = useState(new Set());

  const handleFileRead = (fileContent) => {
    const parser = new XMLParser();
    const jsonObj = parser.parse(fileContent);
    const dataTable = extractDataTable(jsonObj);
    return dataTable;
  };

  const cleanAndParseData = (data) => {

    const cleanedData = data
      .replace(/\\n/g, '')
      .replace(/\n/g, '')
      .replace(/\r/g, '');

    try {
      const parsedArray = JSON.parse(cleanedData);
      return parsedArray;
    } catch (error) {
      console.error("Error parsing cleaned data:", error);
      return [];
    }
  };

  const getAIText = async (pdfFile) => {
    try {
      const formData = new FormData();
      formData.append(
        "file",
        pdfFile
      );
      const uploadResponse = await fetch("/api/v1/extract-pdf-data/upload-pdf-file", {
        method: "POST",
        body: formData,
      });
      if (!uploadResponse.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await uploadResponse.json();
      const uncleanedData = result.textContent
      const cleanedData = cleanAndParseData(uncleanedData)
      dispatch(setExtractedPdfData(cleanedData.filter((item) => item["IDS Form Section Heading"].includes("NON-PATENT") === false)));
      dispatch(setExtractedPdfNplData(cleanedData.filter((item) => item["IDS Form Section Heading"].includes("NON-PATENT") === true)))
      dispatch(setIsPdfLoading(false))
      alert("The extracted data from PDF may not be accurate. Please edit the extracted references data in Initial References table if necessary.")
    } catch (error) {
      if (error.response?.status === 429) {
        setTimeout(() => {
          getAIText(pdfFile);
        }, 60000);
      } else {
        console.error("OpenAI API error:", error);
        dispatch(setIsPdfLoading(false))
      }
    }
  };
  const convertToText = async (pdfFile) => {

    if (processedFiles.has(pdfFile.name)) {
      return;
    }
    dispatch(setIsPdfLoading(true))
    setProcessedFiles((prevState) => new Set(prevState.add(pdfFile.name)));
    getAIText(pdfFile);
  };

  const readFile = (file) => {
    if (file.type === "application/pdf") {
      convertToText(file);
      return;
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = handleFileRead(e.target.result);
          resolve(data);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  useEffect(() => {
    if (files && files.length > 0) {
      Promise.all(files.map((file) => readFile(file)))
        .then((results) => {
          const combinedData = results.flat(); // Combine data from all files
          dispatch(setExtractedData(combinedData));
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
    }
  }, [files, dispatch]);

  return null;
};

const extractUncleanedDataTable = (jsonData) => {
  const dataTable =
    jsonData["uspat:IncomingDocument"]?.["uscom:FormBag"]?.["uscom:Form"]?.[
    "uscom:FormPage"
    ];
  const extractedRows = dataTable
    .map((item) => {
      return item["uscom:FormPageBody"]?.["uscom:DataTable"];
    })
    .filter((item) => item !== undefined);
  return extractedRows;
};

const extractDataTable = (jsonData) => {
  try {
    const dataTable =
      jsonData["uspat:IncomingDocument"]?.["uscom:FormBag"]?.["uscom:Form"]?.[
      "uscom:FormPage"
      ]?.["uscom:FormPageBody"]?.["uscom:DataTable"];
    return Array.isArray(dataTable)
      ? dataTable
      : typeof dataTable === "undefined"
        ? extractUncleanedDataTable(jsonData).flat()
        : [];
  } catch (error) {
    console.error("Error extracting data table:", error);
    return [];
  }
};

export default XMLDataExtractor;
