import { createSlice, createAction } from "@reduxjs/toolkit";

export const revertPatentBiblio = createAction("REVERT_PATENT_BIBLIO");

const initialState = {
  patentBiblioData: null,
  loading: false,
  error: null,
};

const patentBiblioSlice = createSlice({
  name: "patentBiblio",
  initialState,
  extraReducers: (builder) => builder.addCase(revertPatentBiblio, () => initialState),
  reducers: {
    setPatentBiblioData: (state, action) => {
      state.patentBiblioData = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setPatentBiblioData, setLoading, setError } = patentBiblioSlice.actions;

export default patentBiblioSlice.reducer;
