import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCanAddRow,
  setSelectedRefData,
} from "../../store/reducer/searchSlice";
import { checkForHighlight, checkForTick } from "../../util/utils";
import PropTypes from "prop-types";
import "./index.scss";
import RefTick from "../../assets/images/ref-tick.png";
import RefCross from "../../assets/images/ref-cross.png";
import * as XLSX from "xlsx";

const Table = ({
  headers,
  formattedData,
  renderAsHtml,
  handleUserRowData,
  handleUpdateRowData,
  downloadReferences,
  setIsExportPopupOpen,
}) => {
  const [data, setData] = useState(formattedData);
  const canEdit = useSelector((state) => state.search.canEdit);
  const canAddRow = useSelector((state) => state.search.canAddRow);
  const selectedRefData = useSelector((state) => state.search.selectedRefData);
  const selectedNplData = useSelector((state) => state.search.selectedNplData);
  const extractedNplFileUploadData = useSelector(
    (state) => state.search.extractedNplFileUploadData
  );
  const isNPLFileUploaded = useSelector(
    (state) => state.search.isNPLFileUploaded
  );
  const headerData = useSelector((state) => state.admin.data);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();

  const setCursorPosition = (rowIndex, header) => {
    if (canEdit) {
      var element = document.getElementById(
        `editable_span_${rowIndex}_${header}`
      );
      if (element.childNodes.length === 0) {
        const newInput = document.createElement("input");
        newInput.className = "editable-input";
        newInput.textContent = "";
        element.appendChild(newInput);
        element.focus();
      }
    }
  };
  useEffect(() => {
    if (canEdit === true) {
      var element = document.getElementById(`editable_span_0_References`);
      element.focus();
    }
  }, [canEdit]);
  useEffect(() => {
    setData(formattedData);
  }, [formattedData]);

  useEffect(() => {
    if (downloadReferences) {
      exportReferences();
    }
  }, [downloadReferences]);

  const onSelectAll = (selectAll) => {
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row) => ({ ...row, Selected: selectAll }))
        : selectedRefData.map((row) => ({ ...row, Selected: selectAll }));

    setData(updatedData);
    dispatch(setSelectedRefData(updatedData));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    onSelectAll(!selectAll);
  };

  const handleRowSelect = (rowIndex) => {
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          )
        : selectedRefData.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          );

    const allSelected = updatedData.every((row) => row.Selected === true);
    setSelectAll(allSelected);
    setData(updatedData);
    dispatch(setSelectedRefData(updatedData));
  };

  const handleCellChange = (rowIndex, header, event) => {
    const newValue = event.target.textContent;
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, [header]: newValue } : row
          )
        : selectedRefData.map((row, index) =>
            index === rowIndex ? { ...row, [header]: newValue } : row
          );

    setData(updatedData);
    handleUpdateRowData(updatedData);
    handleUserRowData([]);
    if (selectedRefData.length !== 0) {
      dispatch(setSelectedRefData(updatedData));
    }
  };

  const addBlankRow = () => {
    const newRow = headers.reduce(
      (acc, header) => {
        acc[header] = "";
        return acc;
      },
      { Selected: false }
    );

    setData((prevData) => [...prevData, newRow]);
    handleUserRowData((prevData) => [...prevData, newRow]);
    if (selectedRefData.length !== 0) {
      newRow["Sr.No"] = `${selectedRefData.length + 1}`;
      dispatch(setSelectedRefData([...selectedRefData, newRow]));
    }
    dispatch(setCanAddRow(""));
  };

  useEffect(() => {
    if (canAddRow === "add-new-ref") {
      addBlankRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddRow]);

  const exportReferences = () => {
    const filteredSelectedData = selectedRefData.filter(
      (row) => row.Selected === true
    );
    const nplData = selectedNplData.filter((row) => row.Selected === true);
    const nplDataAsObjects = nplData.map((npl, index) => ({
      References: npl["References"],
      Type: "NON-PATENT LITERATURE",
      "Serial #": "",
      "Kind Code": "",
      "Issued / Pub. Date": "",
      "Patentee Name": "",
      "Sr.No": filteredSelectedData.length + index + 1,
      Selected: false,
    }));

    const combinedData = [...filteredSelectedData, ...nplDataAsObjects];
    if (combinedData.length === 0) {
      setIsExportPopupOpen(true);
      return;
    }
    const exportData = combinedData.map(
      ({ Selected, "Sr.No": srNo, ...rest }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "References");
    XLSX.writeFile(workbook, "References.xlsx");
  };

  const checkAllTicks = (item, headerNum) => {
    const serialNumLength = Object.keys(item).length - 7;
    for (let i = 1; i <= serialNumLength; i++) {
      if (
        item.hasOwnProperty(`Serial #${i}`) &&
        item[`Serial #${i}`] === headerNum
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={
                  selectedRefData.length !== 0 &&
                  selectedRefData.filter((item) => item.Selected === true)
                    .length === selectedRefData.length
                    ? true
                    : selectAll
                }
                onChange={handleSelectAll}
                className="checkbox-height"
              />
            </th>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedRefData.length === 0 &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <input
                    type="checkbox"
                    checked={row.Selected}
                    onChange={() => handleRowSelect(rowIndex)}
                    className="checkbox-height"
                  />
                </td>
                {headers.map((header, colIndex) => (
                  <td
                    id={`editable_span_${rowIndex}_${header}`}
                    spellCheck="false"
                    className={`${
                      headerData?.serial_num ===
                      checkForHighlight(header?.slice(0, 2), header?.slice(3))
                        ? "highlight-class"
                        : ""
                    }`}
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    contentEditable={canEdit}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {isNaN(header?.[3]) === false ? (
                      header?.slice(0, 2) === "US" ? (
                        checkForTick(header?.slice(3)) ===
                          row["Serial #"]?.slice(4) ||
                        checkForTick(header?.slice(3)) === row?.["Serial #"] ||
                        checkAllTicks(row, checkForTick(header?.slice(3))) ===
                          true ? (
                          <img src={RefTick} className="tick-cross"></img>
                        ) : (
                          <img src={RefCross} className="tick-cross"></img>
                        )
                      ) : header?.slice(3) === row?.["Serial #"] ||
                        checkAllTicks(row, header?.slice(3)) === true ? (
                        <img src={RefTick} className="tick-cross"></img>
                      ) : (
                        row[header]
                      )
                    ) : !renderAsHtml || header !== "Link" ? (
                      row[header]
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          {selectedRefData.length !== 0 &&
            selectedRefData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <input
                    type="checkbox"
                    checked={row.Selected}
                    onChange={() => handleRowSelect(rowIndex)}
                    className="checkbox-height"
                  />
                </td>
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    className={`${
                      headerData?.serial_num ===
                      checkForHighlight(header?.slice(0, 2), header?.slice(3))
                        ? "highlight-class"
                        : ""
                    }`}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    id={`editable_span_${rowIndex}_${header}`}
                    spellCheck="false"
                    contentEditable={canEdit}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {isNaN(header?.[3]) === false ? (
                      header?.slice(0, 2) === "US" ? (
                        checkForTick(header?.slice(3)) ===
                          row["Serial #"]?.slice(4) ||
                        checkForTick(header?.slice(3)) === row?.["Serial #"] ||
                        checkAllTicks(row, checkForTick(header?.slice(3))) ===
                          true ? (
                          <img src={RefTick} className="tick-cross"></img>
                        ) : (
                          <img src={RefCross} className="tick-cross"></img>
                        )
                      ) : header?.slice(3) === row?.["Serial #"] ||
                        checkAllTicks(row, header?.slice(3)) === true ? (
                        <img src={RefTick} className="tick-cross"></img>
                      ) : (
                        row[header]
                      )
                    ) : !renderAsHtml || header !== "Link" ? (
                      row[header]
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
