import React, { useState } from "react";
import "./create-letter.scss";
import Button from "../common/button";
import Dropdown from "../common/dropdown";
import Input from "../common/input";
import CloseIcon from "../../assets/images/create-letter-close.png";
import PenImage from "../../assets/images/create-letter-pen.png";
const CreateLetterPopup = ({ onClose }) => {
  const [clientOption, setClientOption] = useState("Select Client");
  const [emailRadioOption, setEmailRadioOption] = useState("");
  const handleRadioChange = (e) => {
    setEmailRadioOption(e.target.value);
  };
  const clientdropdownOptions = [
    {
      value: "Select Client",
      label: "Select Client",
      disabled: false,
    },
    {
      value: "default",
      label: "Default",
      disabled: false,
    },
  ];
  const templatedropdownOptions = [
    {
      value: "Select Template",
      label: "Select Template",
      disabled: false,
      hidden: true,
    },
  ];
  const numberdropdownOptions = [
    { value: "Registration #", label: "Registration #", disabled: false },
    {
      value: "Reference/Docket #",
      label: "Reference/Docket #",
      disabled: false,
    },
  ];

  return (
    <div className="create-letter-popup-overlay">
      <div className="create-letter-popup d-flex flex-column align-items-center justify-content-center">
        <div className="message">
          <span>Create Letter</span>
          <img
            src={CloseIcon}
            onClick={onClose}
            className="close-button close-icon-cross"
            alt="cross"
          />
        </div>
        <div className="d-flex submessage py-4 flex-column align-items-center">
          <div className="d-flex flex-column email-input-container">
            <div className="email-input-title">Email/Input</div>
            <div className="d-flex flex-row email-input-radio-container mt-2 px-3 py-3 align-items-center justify-content-evenly">
              <div className="d-flex flex-row align-items-center radio-margin">
                <Input
                  type="radio"
                  value="manual"
                  checked={emailRadioOption === "manual"}
                  onChange={handleRadioChange}
                  className={`create-radio me-2`}
                />
                <label className="radio-label ms-1">Manual Input</label>
              </div>
              <div className="d-flex flex-row align-items-center radio-margin">
                <Input
                  type="radio"
                  value="upload"
                  checked={emailRadioOption === "upload"}
                  onChange={handleRadioChange}
                  className={`create-radio me-2`}
                />
                <label className="radio-label ms-1">Upload Input</label>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column email-input-container mt-3">
            <div className="email-input-title">Select Client</div>
            <div className="d-flex flex-column select-client-input-container justify-content-center">
              <div className="d-flex flex-row mt-2 px-3 py-1 align-items-center justify-content-around">
                <Dropdown
                  options={clientdropdownOptions}
                  className={`mx-1 select-client-input`}
                  value={clientOption}
                  onChange={(e) => setClientOption(e.target.value)}
                />
                <Dropdown
                  options={templatedropdownOptions}
                  className={`mx-1 select-client-input`}
                />
              </div>
              <div className="d-flex flex-column px-1 mb-2">
                {clientOption === "default" && (
                  <div className="file-wrapper px-3">
                    <div className="d-flex flex-row signature-wrapper px-2 py-1 mt-2">
                      <img
                        src={PenImage}
                        alt="pen"
                        className="pen-image mt-1"
                      />
                      <span className="signature-text px-3 py-1">
                        Attach Signature
                      </span>
                    </div>
                    <Input type="file" className={`attach-input`} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column email-input-container mt-3">
            <div className="email-input-title">Select Number</div>
            <div className="d-flex flex-row select-number-input-container mt-2 px-3 py-3 align-items-center justify-content-around">
              <Dropdown
                options={numberdropdownOptions}
                value="Registration #"
                className={`mx-1 select-number-input`}
              />
              <Input
                className={`mx-1 px-1 number-input`}
                placeholder="Enter Number"
              />
            </div>
          </div>
          <div className="d-flex flex-column create-btn-container mt-5">
            <Button className={`create-btn-style`}>Create</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLetterPopup;
