import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Input from "../../components/common/input";
import Button from "../../components/common/button";
import "./login.scss";
import Form from "../../components/common/Form";
import {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} from "../../store/action/loginApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const { resetToken } = useParams();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  const [loginUserApi, { isLoading: isLoginLoading }] = useLoginMutation();
  const [forgotPasswordApi, { isLoading: isForgotPasswordLoading }] =
    useForgotPasswordMutation();
  const [resetPasswordApi, { isLoading: isResetLoading }] =
    useResetPasswordMutation();
  const [loginError, setLoginError] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(faEyeSlash);

  useEffect(() => {
    if (resetToken) {
      setForgotPassword(false);
    }
  }, [resetToken]);

  useEffect(() => {
    setTimeout(() => {
      if (loginError !== "") {
        setLoginError("");
      }
    }, 2000);
  }, [loginError]);

  const handleVisibility = () => {
    if (type === "password") {
      setIcon(faEye);
      setType("text");
    } else {
      setIcon(faEyeSlash);
      setType("password");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (forgotPassword) {
      if (!validateEmail(email)) {
        setEmailError("Please enter a valid email address.");

        setTimeout(() => {
          setEmailError("");
        }, 3000);

        return;
      }

      await forgotPasswordApi({ email }).then(({ data }) => {
        if (data.success) {
          alert("Password reset link sent to your email.");
          setForgotPassword(false);
          setEmail("");
        } else {
          console.log("Failed to send password reset link.");
        }
      });
    } else {
      const userData = {
        email: username,
        password: password,
      };

      if (!isLoginLoading) {
        await loginUserApi(userData).then(({ data: { accessToken } = {} }) => {
          if (accessToken) {
            // Decode the access token to get user info
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.userId;
            sessionStorage.setItem("userId", userId);
            if (rememberMe) {
              localStorage.setItem("accessToken", accessToken);
              sessionStorage.setItem("accessToken", accessToken);
            } else {
              sessionStorage.setItem("accessToken", accessToken);
            }
            sessionStorage.setItem("userEmail", username);
            setLoginError("");
            navigate("/welcome");
          } else {
            setLoginError("Invalid email or password");
            console.log("token not found");
          }
        });
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    await resetPasswordApi({ resetToken, newPassword }).then(({ data }) => {
      if (data.success) {
        alert("Password reset successful. Please log in.");
        navigate("/login");
      } else {
        setError("Failed to reset password.");
      }
    });
  };

  return (
    <div className="login-page-container d-flex justify-content-between">
      <div className="login-left">
        <h1 className="welcome-left">
          Welcome to <span>IDS Sign in</span>
        </h1>
        <p className="para">
          The Information Disclosure Statement (IDS) is a crucial document used
          in patent applications to disclose relevant prior art and information
          that may impact the patentability of your invention.
        </p>
      </div>
      <div className="login-right">
        <div className="section-right">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="welcome-right">
              Welcome to <span>IDS</span>
              <br />
              <h1 className="sign-in mt-2">
                {resetToken
                  ? "Reset Password"
                  : forgotPassword
                  ? "Forgot Password"
                  : "Login"}
              </h1>
            </h2>
          </div>
          <Form
            onSubmit={resetToken ? handleResetPassword : handleLogin}
            className="login-form"
          >
            {resetToken ? (
              <>
                <label className="login-label">New Password</label>
                <div className="mb-3">
                  <Input
                    name="newPassword"
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <label className="login-label">Confirm Password</label>
                <div className="mb-3">
                  <Input
                    name="confirmPassword"
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                {error && <p className="text-danger">{error}</p>}
                <Button
                  type="submit"
                  className="btn btn-primary w-100 fw-6 login-button"
                >
                  {isResetLoading ? "Resetting..." : "Reset Password"}
                </Button>
              </>
            ) : forgotPassword ? (
              <>
                <label className="login-label">User ID</label>
                <div className="mb-3">
                  <Input
                    name="email"
                    type="email"
                    className={`form-control ${
                      emailError !== "" ? "is-field-invalid" : ""
                    }`}
                    placeholder="Enter User ID"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {emailError && <p className="text-danger">{emailError}</p>}{" "}
                {/* Error for invalid email */}
                <Button
                  type="submit"
                  className="btn btn-primary w-100 fw-6 login-button"
                >
                  {isForgotPasswordLoading ? "Sending..." : "Send"}
                </Button>
                <div className="mt-3">
                  <span
                    className="back-to-sign-in"
                    onClick={() => setForgotPassword(false)}
                  >
                    Back to login
                  </span>
                </div>
              </>
            ) : (
              <>
                <label className="login-label">User ID</label>
                <div className="mb-3">
                  <Input
                    name="email"
                    type="text"
                    className={`form-control ${
                      loginError !== "" ? "is-field-invalid" : ""
                    }`}
                    placeholder="User ID"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
                <label className="login-label">Enter Your Password</label>
                <div className="mb-3 d-flex flex-row align-items-center password-box">
                  <Input
                    name="password"
                    type={type}
                    className={`form-control ${
                      loginError !== "" ? "is-field-invalid" : ""
                    }`}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span
                    className={`d-flex icon-box ${
                      type === "text" ? "icon-opacity" : ""
                    }`}
                    onClick={handleVisibility}
                  >
                    <FontAwesomeIcon icon={icon} />
                  </span>
                </div>
                {loginError !== "" && (
                  <span className="display-error">{loginError}</span>
                )}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      name="rememberMe"
                      className="mr-2"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <label htmlFor="rememberMe" className="remember-me">
                      Remember Me
                    </label>
                  </div>
                  <span
                    className="forgot-password"
                    onClick={() => setForgotPassword(true)}
                  >
                    Forgot Password?
                  </span>
                </div>
                <Button
                  type="submit"
                  className="btn btn-primary w-100 fw-6 login-button"
                >
                  {isLoginLoading ? "Signing in..." : "Sign in"}
                </Button>
              </>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
