import React from "react";
import { useNavigate } from "react-router-dom";
import { setIsLetterPopupOpen } from "../../store/reducer/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateLetterPopup from "../../components/popup/create-letter-popup";
import IDSImage from "../../assets/images/welcome-ids.png";
import ReportImage from "../../assets/images/welcome-reporting-letter.png";
import OfficeImage from "../../assets/images/welcome-office-action.png";
import FilingImage from "../../assets/images/welcome-filing-package.png";
import ProofImage from "../../assets/images/welcome-proof-reading.png";
import PostImage from "../../assets/images/welcome-post-allowance.png";
import DocketingImage from "../../assets/images/welcome-patent-docketing.png";
import TrademarkImage from "../../assets/images/welcome-trademark-docketing.png";
import "./welcome.scss";
const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLetterPopupOpen = useSelector(
    (state) => state.search.isLetterPopupOpen
  );
  const handleIDSClick = () => {
    if (sessionStorage.getItem("accessToken")) {
      navigate("/dashboard");
      return;
    }
    navigate("/login");
  };
  const handleReportClick = () => {
    if (sessionStorage.getItem("accessToken")) {
      dispatch(setIsLetterPopupOpen(true));
      return;
    }
    navigate("/login");
  };
  const onCloseLetterPopup = () => {
    dispatch(setIsLetterPopupOpen(false));
  };
  return (
    <div className="welcome-container">
      <div className="d-flex flex-column ms-4">
        <div className="d-flex flex-column welcome-header">
          <div className="welcome-title">
            Welcome <span className="welcome-user-title">User</span>
          </div>
          <div className="welcome-text">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </div>
        </div>
        <div className="container card-container ms-4">
          <div className="row row-cols-4">
            <div
              class="card tool-card col d-flex flex-row align-items-center"
              title="Go to IDS Tool"
              onClick={handleIDSClick}
            >
              <div className="d-flex flex-row align-items-center">
                <img src={IDSImage} className="card-image-1" alt="ids" />
                <div class="card-body">
                  <h5 class="card-title">Information disclosure statement</h5>
                </div>
              </div>
            </div>
            <div
              class="card tool-card mx-2 col d-flex flex-row align-items-center"
              title="Go to Reporting Letter"
              onClick={handleReportClick}
            >
              <div className="d-flex flex-row align-items-center">
                <img
                  src={ReportImage}
                  className="card-image"
                  alt="reporting-letter"
                />
                <div class="card-body">
                  <h5 class="card-title">Reporting Letter</h5>
                </div>
              </div>
            </div>
            <div class="card tool-card mx-2 col d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center">
                <img
                  src={OfficeImage}
                  className="card-image"
                  alt="office-action"
                />
                <div class="card-body">
                  <h5 class="card-title">Office Action Response</h5>
                  <h4 class="card-title soon-text">Outbreaking</h4>
                </div>
              </div>
            </div>
            <div class="card tool-card mx-2 col d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center">
                <img
                  src={FilingImage}
                  className="card-image"
                  alt="Filing Package"
                />
                <div class="card-body">
                  <h5 class="card-title">Filing Package</h5>
                  <h4 class="card-title soon-text">Outbreaking</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-cols-4 mt-3">
            <div
              class="card tool-card col d-flex flex-row align-items-center"
              title="Go to IDS Tool"
              onClick={() => {}}
            >
              <div className="d-flex flex-row align-items-center">
                <img
                  src={DocketingImage}
                  className="card-image"
                  alt="Patent Docketing"
                />
                <div class="card-body">
                  <h5 class="card-title docket-title">Patent Docketing</h5>
                  <h4 class="card-title soon-text out-breaking-margin-2">
                    Outbreaking
                  </h4>
                </div>
              </div>
            </div>
            <div class="card tool-card mx-2 col d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center">
                <img
                  src={PostImage}
                  className="card-image"
                  alt="Post Allowance"
                />
                <div class="card-body">
                  <h5 class="card-title">Post Allowance Review</h5>
                  <h4 class="card-title soon-text out-breaking-margin">
                    Outbreaking
                  </h4>
                </div>
              </div>
            </div>
            <div class="card tool-card mx-2 col d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center">
                <img
                  src={ProofImage}
                  className="card-image"
                  alt="Proof Reading"
                />
                <div class="card-body">
                  <h5 class="card-title">Proofreading</h5>
                  <h4 class="card-title soon-text out-breaking-margin-2">
                    Outbreaking
                  </h4>
                </div>
              </div>
            </div>
            <div class="card tool-card mx-2 col d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center">
                <img
                  src={TrademarkImage}
                  className="card-image"
                  alt="Trademark"
                />
                <div class="card-body">
                  <h5 class="card-title">Trademark Docketing</h5>
                  <h4 class="card-title soon-text out-breaking-margin">
                    Outbreaking
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLetterPopupOpen && <CreateLetterPopup onClose={onCloseLetterPopup} />}
    </div>
  );
};

export default Welcome;
