import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { availableReferencesApi } from '../action/availableReferencesApi';

export const revertAllAvailableReferencesData = createAction("REVERT_ALL_AVAILABLE_REF_DATA");

// Async thunk for saving available references
export const saveAvailableReferences = createAsyncThunk(
    'availableReferences/save',
    async (data, thunkAPI) => {
        try {
            const response = await thunkAPI.dispatch(
                availableReferencesApi.endpoints.createAvailableReference.initiate(data)
            );

            return response;
        } catch (error) {
            console.error('API Call Failed:', error);
            return thunkAPI.rejectWithValue(error.message || 'Failed to save data');
        }
    }
);

const initialState = {
    data: [],
    status: 'idle',
    error: null,
    formattedAvailableReferenceData: [],
};

// Slice definition
const availableReferencesSlice = createSlice({
    name: 'availableReferences',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(revertAllAvailableReferencesData, () => initialState)
            .addCase(saveAvailableReferences.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveAvailableReferences.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                state.error = null;
            })
            .addCase(saveAvailableReferences.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to save available references';
            });
    },
    reducers: {
        setFormattedAvailableRefData: (state, action) => {
            state.formattedAvailableReferenceData = action.payload;
        },
    },
});

export const { setFormattedAvailableRefData } = availableReferencesSlice.actions;

export default availableReferencesSlice.reducer;
