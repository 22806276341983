import React from "react";
import "./index.scss";
import Loading from "../../assets/images/loading.png";

const Popup = () => {
  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="message">
          <span>Downloading</span>
        </div>
        <div className="submessage generate-message pb-2">Please wait</div>
        <div className="">
          <img src={Loading} className="loading-icon" alt="Loading" />
        </div>
      </div>
    </div>
  );
};

export default Popup;
