import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { revertAll } from "../../store/reducer/searchSlice";
import { revertPatentBiblio } from "../../store/reducer/patentBiblioSlice";
import { revertAllFamilyData } from "../../store/reducer/familySlice";
import { revertAllReferencesData } from "../../store/reducer/referencesSlice";
import { revertAllInitialRefData } from "../../store/reducer/initialReferencesSlice";
import { revertAllAvailableReferencesData } from "../../store/reducer/availableReferencesSlice"
import { useTranslation, Trans } from "react-i18next";
import logoutButton from "../../assets/images/logout-outlined-button.png"
import Button from "../common/button";
import "./index.scss";
import logo from "../../assets/images/Logo.png";
import useAuth from "../../hooks/useAuth";
import { useGetUserProfileQuery } from "../../store/action/userApi";
const Header = ({ isAuthenticate }) => {
  useAuth();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = location.pathname;
  const popupOpenClick = useSelector((state) => state.search.popupOpenClick);
  const radioOption = useSelector((state) => state.search.radioOption);
  const isLetterPopupOpen = useSelector(
    (state) => state.search.isLetterPopupOpen
  );
  const dropdownRef = useRef(null);
  const handleLogout = () => {
    setOpenDropdown(false);
    sessionStorage.clear();
    dispatch(revertAll());
    dispatch(revertPatentBiblio())
    dispatch(revertAllFamilyData());
    dispatch(revertAllReferencesData());
    dispatch(revertAllInitialRefData())
    dispatch(revertAllAvailableReferencesData())
    navigate("/login");
  };


  const handleNavigation = (path) => {
    dispatch(revertAll());
    dispatch(revertPatentBiblio())
    dispatch(revertAllFamilyData());
    dispatch(revertAllReferencesData());
    dispatch(revertAllInitialRefData());
    dispatch(revertAllAvailableReferencesData())
    navigate(path);
  };

  const userEmail = sessionStorage.getItem("userEmail");
  const getUsername = (email) => {
    if (!email) return "";
    const namePart = email.split("@")[0];
    const firstName = namePart.split(".")[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1);
  };

  const username = getUsername(userEmail);
  const [userName, setUserName] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const { data } = useGetUserProfileQuery({ userEmail: userEmail });
  const profileInitial = userName ? userName.charAt(0).toUpperCase() : "";
  const toggleDropdown = () => setOpenDropdown((prevState) => !prevState);

  useEffect(() => {
    if (data) {
      setUserName(data.name);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    isLetterPopupOpen === false && (<div
      className={`container-fluid sticky-top ps-0 pe-0 header-bg shadow-sm ${isAuthenticate ? "dark-bg" : ""
        }`}
      id="header"
    >
      <div className="w-100">
        <nav className="navbar width-adjust-navbar navbar-expand-lg header-bg navbar-light py-3 ps-1 pe-1 py-lg-0">
          <div
            className="navbar-brand ps-4"
            style={sessionStorage.getItem("accessToken") ? { cursor: "pointer" } : {}}
          >
            <img src={logo} alt="Logo" className="logo" title={sessionStorage.getItem("accessToken") ? "Navigate to dashboard" : ""} onClick={() => {
              if (sessionStorage.getItem("accessToken")) {
                handleNavigation('/dashboard')
                return;
              }
            }
            } />
          </div>
          {pathname !== "/login" && pathname !== "/welcome" && (
            <>
              <Button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </Button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                  <div
                    className={`nav-item nav-link ${pathname === "/search" ? "active" : ""
                      }`}
                    onClick={() =>
                      popupOpenClick === true
                        ? handleNavigation("/search")
                        : handleNavigation("/search")
                    }
                  >
                    <Trans>{t("Home")}</Trans>
                  </div>
                  <div
                    className={`nav-item nav-link ${pathname === "/dashboard" ? "active" : ""
                      }`}
                    onClick={() => handleNavigation("/dashboard")}
                  >
                    <Trans>{t("Dashboard")}</Trans>
                  </div>
                  {pathname === "/ids" && (
                    <div
                      className={`nav-item nav-link ${pathname === "/ids" ? "active" : ""
                        }`}
                      onClick={() => handleNavigation("/ids")}
                    >
                      <Trans>
                        {radioOption === "option2"
                          ? t("Supplementary IDS")
                          : t("Initial IDS")}
                      </Trans>
                    </div>
                  )}
                  <div className="navbar-nav ms-auto py-0">
                    <div className="dropdown-title" ref={dropdownRef}>
                      {userEmail && username ? (
                        <>
                          <span className="username-text" title={userName}>{userName}</span>
                          <div
                            className="profile-initial"
                            onClick={toggleDropdown}
                          >
                            {profileInitial}
                          </div>
                          {openDropdown === true && <div class="dropdown-content d-flex flex-row align-items-center justify-content-center">
                            <img src={logoutButton} width="20px" height="20px" onClick={handleLogout} title="Logout" style={{ cursor: "pointer" }} />
                            <a href="" className="px-2" onClick={handleLogout} style={{ cursor: 'pointer', pointerEvents: 'auto' }}>Log Out</a>
                          </div>}
                        </>
                      ) : (
                        <Trans>{t("Username")}</Trans>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {pathname === "/welcome" && (
            <>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                  <div
                    className={`nav-item nav-link ${pathname === "/welcome" ? "active" : ""
                      }`}
                    onClick={() => { }}
                  >
                    <Trans>{t("Home")}</Trans>
                  </div>
                  <div className="navbar-nav ms-auto py-0">
                    <div className="dropdown-title" ref={dropdownRef}>
                      {userEmail && username ? (
                        <>
                          <span className="username-text" title={userName}>{userName}</span>
                          <div
                            className="profile-initial"
                            onClick={toggleDropdown}
                          >
                            {profileInitial}
                          </div>
                          {openDropdown === true && <div class="dropdown-content d-flex flex-row align-items-center justify-content-center">
                            <img src={logoutButton} width="20px" height="20px" onClick={handleLogout} title="Logout" style={{ cursor: "pointer" }} />
                            <a href="" className="px-2" onClick={handleLogout} style={{ cursor: 'pointer', pointerEvents: 'auto' }}>Log Out</a>
                          </div>}
                        </>
                      ) : (
                        <Trans>{t("Username")}</Trans>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {pathname === "/login" && (
            <div className="navbar-nav ms-auto py-0">
              <div className="dropdown-title">
                {userEmail ? (
                  <>
                    <span className="username-text">{username}</span>
                    <div
                      className="profile-initial"
                      onClick={handleLogout}
                      title="Logout"
                    >
                      {profileInitial}
                    </div>
                  </>
                ) : (
                  <Trans>{t(" ")}</Trans>
                )}
              </div>
            </div>
          )}
        </nav>
      </div>
    </div>)
  );
};

export default Header;
