import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const availableReferencesApi = createApi({
    reducerPath: "availableReferencesApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        createAvailableReference: builder.mutation({
            query: (data) => ({
                url: 'available-references',
                method: 'POST',
                body: data,
            }),
        }),
        getSavedAvailableRefByUser: builder.query({
            query: ({ userId, num }) => `available-references/saved-available-refs/${userId}/${num}`,
            keepUnusedDataFor: 0
        }),
        deleteSavedAvailableRefByUser: builder.mutation({
            query: ({ userId, num }) => ({
                url: `available-references/saved-available-refs-delete/${userId}/${num}`,
                method: 'DELETE',
            })
        })
    }),
});

export const { useCreateAvailableReferenceMutation, useGetSavedAvailableRefByUserQuery, useDeleteSavedAvailableRefByUserMutation } = availableReferencesApi;
export default availableReferencesApi;

