import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import loginApi from "./action/loginApi";
import searchReducer from "../store/reducer/searchSlice";
import familyReducer from "../store/reducer/familySlice";
import patentBiblioReducer from "../store/reducer/patentBiblioSlice";
import initialReferencesReducer from "../store/reducer/initialReferencesSlice";
import refrencesReducer from "../store/reducer/referencesSlice";
import adminReducer from "../store/reducer/adminSlice";
import generateReducer from "../store/reducer/generateSlice";
import topContentReducer from "../store/reducer/topContentSlice"
import availableReferencesReducer from "../store/reducer/availableReferencesSlice"

import familyBiblioApi from "./action/familyBiblioApi";
import patentBiblioApi from "./action/patentBiblioApi";
import epoBiblioApi from "./action/epoBiblioApi";
import initialReferencesApi from "./action/initialReferencesApi";
import referencesApi from "./action/referencesApi";
import userApi from "./action/userApi";
import adminApi from "./action/adminApi";
import generateApi from "./action/generateApi";
import topContentApi from "./action/topContentApi";
import availableReferencesApi from "./action/availableReferencesApi";

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [familyBiblioApi.reducerPath]: familyBiblioApi.reducer,
    [patentBiblioApi.reducerPath]: patentBiblioApi.reducer,
    [epoBiblioApi.reducerPath]: epoBiblioApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [initialReferencesApi.reducerPath]: initialReferencesApi.reducer,
    [referencesApi.reducerPath]: referencesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [generateApi.reducerPath]: generateApi.reducer,
    [topContentApi.reducerPath]: topContentApi.reducer,
    [availableReferencesApi.reducerPath]: availableReferencesApi.reducer,

    search: searchReducer,
    family: familyReducer,
    patentBiblio: patentBiblioReducer,
    initialReferences: initialReferencesReducer,
    references: refrencesReducer,
    availableReferences: availableReferencesReducer,
    admin: adminReducer,
    topContent: topContentReducer,
    generate: generateReducer
  },
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(familyBiblioApi.middleware)
      .concat(epoBiblioApi.middleware)
      .concat(loginApi.middleware)
      .concat(patentBiblioApi.middleware)
      .concat(initialReferencesApi.middleware)
      .concat(referencesApi.middleware)
      .concat(userApi.middleware)
      .concat(adminApi.middleware)
      .concat(generateApi.middleware)
      .concat(topContentApi.middleware)
      .concat(availableReferencesApi.middleware);
  },
});

setupListeners(store.dispatch);

export default store;
