import { createSlice, createAction } from "@reduxjs/toolkit";


export const revertAll = createAction("REVERT_ALL");

const initialState = {
  type: "",
  number: "",
  number1: "",
  number2: "",
  extractedData: [],
  extractedPdfData: [],
  extractedPdfNplData: [],
  formattedInitialRefData: [],
  extractedExcelData: [],
  extractedNplFileUploadData: [],
  docketNumber: "",
  radioOption: "",
  filesUploaded: [],
  additionalRefInput: "",
  correspondingFamInput: "",
  addRefData: [],
  canEdit: false,
  canNplEdit: false,
  canAddRow: "",
  formattedIdsData: [],
  formattedIdsSortedData: [],
  discardedIdsData: [],
  selectedRefData: [],
  selectedNplData: [],
  activeReduxTab: "",
  discardedNplData: [],
  extractedNplData: [],
  familyNplData: [],
  formattedNplData: [],
  usPublNum: "",
  plainSearchNum: "",
  popupNumber: "",
  popupNewIdsNumber: "",
  popupIdsType: "",
  popupNewIdsType: "",
  popupDocketNumber: "",
  popupOpenClick: false,
  popupDownload: false,
  isNPLFileUploaded: false,
  canTopEdit: false,
  isLoading: false,
  isPdfLoading: false,
  isLetterPopupOpen: false
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(revertAll, () => initialState)
      .addMatcher(
        (action) => action.type === "search/setSearchParameters",
        (state, action) => {
          if (state.radioOption !== action.payload.radioOption) {
            return initialState;
          }
          state.type = action.payload.type;
          state.number = action.payload.number;
          state.number1 = action.payload.number1;
          state.number2 = action.payload.number2;
          state.plainSearchNum = action.payload.plainSearchNum;
          state.radioOption = action.payload.radioOption;
          state.filesUploaded = action.payload.filesUploaded;
          state.correspondingFamInput = action.payload.correspondingFamInput;
          state.additionalRefInput = action.payload.additionalRefInput;
        }
      );
  },
  reducers: {
    setSearchParameters: (state, action) => {
      state.type = action.payload.type;
      state.number = action.payload.number;
      state.radioOption = action.payload.radioOption;
      state.filesUploaded = action.payload.filesUploaded;
      state.correspondingFamInput = action.payload.correspondingFamInput;
      state.additionalRefInput = action.payload.additionalRefInput;
      state.isLoading = action.payload.isLoading;
    },
    setPlainSearchNum: (state, action) => {
      state.plainSearchNum = action.payload;
    },
    setUsPublNum: (state, action) => {
      state.usPublNum = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsPdfLoading: (state, action) => {
      state.isPdfLoading = action.payload;
    },
    setExtractedData: (state, action) => {
      state.extractedData = action.payload;
    },
    setExtractedPdfData: (state, action) => {
      state.extractedPdfData = action.payload;
    },
    setExtractedPdfNplData: (state, action) => {
      state.extractedPdfNplData = action.payload;
    },
    setFormattedInitialRefData: (state, action) => {
      state.formattedInitialRefData = action.payload;
    },
    setExtractedNplFileUploadData: (state, action) => {
      state.extractedNplFileUploadData = action.payload;
    },
    setExtractedExcelData: (state, action) => {
      state.extractedExcelData = action.payload;
    },
    setDocketNumber: (state, action) => {
      state.docketNumber = action.payload;
    },
    setAddRefData: (state, action) => {
      state.addRefData = action.payload;
    },
    setCanEdit: (state, action) => {
      state.canEdit = action.payload;
    },
    setCanNplEdit: (state, action) => {
      state.canNplEdit = action.payload;
    },
    setCanTopEdit: (state, action) => {
      state.canTopEdit = action.payload;
    },
    setCanAddRow: (state, action) => {
      state.canAddRow = action.payload;
    },
    setFormattedIdsData: (state, action) => {
      state.formattedIdsData = action.payload;
    },
    setFormattedIdsSortedData: (state, action) => {
      state.formattedIdsSortedData = action.payload;
    },
    setDiscardedIdsData: (state, action) => {
      state.discardedIdsData = action.payload;
    },
    setSelectedRefData: (state, action) => {
      state.selectedRefData = action.payload;
    },
    setSelectedNplData: (state, action) => {
      state.selectedNplData = action.payload;
    },
    setActiveReduxTab: (state, action) => {
      state.activeReduxTab = action.payload;
    },
    setDiscardedNplData: (state, action) => {
      state.discardedNplData = action.payload;
    },
    setExtractedNplData: (state, action) => {
      state.extractedNplData = action.payload;
    },
    setFamilyNplData: (state, action) => {
      state.familyNplData = action.payload;
    },
    setFormattedNplData: (state, action) => {
      state.formattedNplData = action.payload;
    },
    setPopupNumber: (state, action) => {
      state.popupNumber = action.payload;
    },
    setPopupNewIdsNumber: (state, action) => {
      state.popupNewIdsNumber = action.payload;
    },
    setPopupIdsType: (state, action) => {
      state.popupIdsType = action.payload;
    },
    setPopupNewIdsType: (state, action) => {
      state.popupNewIdsType = action.payload;
    },
    setPopupDocketNumber: (state, action) => {
      state.popupDocketNumber = action.payload;
    },
    setPopupOpenClick: (state, action) => {
      state.popupOpenClick = action.payload;
    },
    setPopupDownload: (state, action) => {
      state.popupDownload = action.payload;
    },
    setNPLFileUploaded: (state, action) => {
      state.isNPLFileUploaded = action.payload;
    },
    setIsLetterPopupOpen: (state, action) => {
      state.isLetterPopupOpen = action.payload
    }
  },
});

export const {
  setSearchParameters,
  setExtractedData,
  setExtractedPdfData,
  setExtractedPdfNplData,
  setFormattedInitialRefData,
  setExtractedExcelData,
  setExtractedNplFileUploadData,
  setDocketNumber,
  setAddRefData,
  setCanEdit,
  setCanNplEdit,
  setCanTopEdit,
  setCanAddRow,
  setFormattedIdsData,
  setFormattedIdsSortedData,
  setDiscardedIdsData,
  setSelectedRefData,
  setSelectedNplData,
  setActiveReduxTab,
  setDiscardedNplData,
  setExtractedNplData,
  setFamilyNplData,
  setFormattedNplData,
  setPopupNumber,
  setPopupNewIdsNumber,
  setPopupIdsType,
  setPopupNewIdsType,
  setPopupDocketNumber,
  setPopupOpenClick,
  setPopupDownload,
  setNPLFileUploaded,
  setIsLoading,
  setIsPdfLoading,
  setUsPublNum,
  setPlainSearchNum,
  setIsLetterPopupOpen
} = searchSlice.actions;
export default searchSlice.reducer;
