import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../common/family-table';
import * as XLSX from 'xlsx';
import { formatDate, PRIORITY_ORDER, formatSavedDate } from '../../../util/utils';
import { useGetPatentBiblioMutation } from '../../../store/action/patentBiblioApi';
import { setFormattedFamilyData, setFormattedSaveFamilyData } from '../../../store/reducer/familySlice';
import { setFormattedAvailableRefData } from '../../../store/reducer/availableReferencesSlice';
import { setIsLoading } from '../../../store/reducer/searchSlice';
import { useGetSavedFamilyByUserQuery } from '../../../store/action/familyBiblioApi';
import { useGetSavedAvailableRefByUserQuery } from '../../../store/action/availableReferencesApi';
import { getFamilyOriginalNumber } from '../../../util/utils';
import './family-information.scss';
import { highlightText } from '../../../utils/highlight';
import { useLocation } from "react-router-dom";
const FamilyInformation = ({ searchTerm, selectedColumn, downloadFamily }) => {
  const { familyBiblio } = useSelector((state) => state.family);
  const { state } = useLocation();
  const { number: pubNum } = state || {};
  const number = useSelector((state) => state.search.number)
  const dispatch = useDispatch();
  const familyMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member'];
  const familyMembersArray = useMemo(
    () => (Array.isArray(familyMembers) ? familyMembers : familyMembers ? [familyMembers] : []),
    [familyMembers]
  );
  const userNumber = sessionStorage.getItem("userId")
  const { data } = useGetSavedFamilyByUserQuery({ userId: userNumber, num: number !== '' ? number : pubNum })
  const { data: savedAvailRef } = useGetSavedAvailableRefByUserQuery({ userId: userNumber, num: number !== '' ? number : pubNum })
  const savedFamilyArray = data ? [...data] : []
  const savedAvailRefArray = savedAvailRef ? [...savedAvailRef] : []
  const [getPatentBiblio] = useGetPatentBiblioMutation();
  const [patentData, setPatentData] = useState({});
  const [addFamilyRowData, setAddFamilyRowData] = useState([])
  const [updateFamilyRowData, setUpdateFamilyRowData] = useState([])
  const handleUpdateFamilyRowData = (data) => {
    setUpdateFamilyRowData(data)
  }
  const handleAddFamilyRowData = (data) => {
    setAddFamilyRowData(data)
  }
  useEffect(() => {
    let isMounted = true;

    const fetchPatentData = async () => {
      const serialNumbers = familyMembersArray.map(member => {
        const applicationNumber = member['application-reference']?.['document-id']?.['doc-number']?.['$'];
        if (applicationNumber) {
          const cleanedSerialNumber = applicationNumber.replace(/^\d{4}/, '').slice(-8);
          return cleanedSerialNumber.length === 8 ? cleanedSerialNumber : applicationNumber;
        }
        return null;
      }).filter(Boolean);

      if (serialNumbers.length === 0) {
        setPatentData({});
        return;
      }

      const requests = serialNumbers.map(serialNumber =>
        getPatentBiblio({ criteria: `patentApplicationNumber:${serialNumber}`, start: 0, rows: 1 }).unwrap()
      );

      try {
        const results = await Promise.all(requests);

        if (!isMounted) return;

        const dataMap = results.reduce((acc, result) => {
          result.response.docs.forEach(doc => {
            const serialNumber = doc.patentApplicationNumber?.[0] || '';
            acc[serialNumber] = {
              status: doc.accessLevelCategory?.[0] || '',
              docketNo: doc.applicantFileReference?.[0] || '',
            };
          });
          return acc;
        }, {});

        setPatentData(dataMap);
      } catch (err) {
        console.error('Error fetching patent data:', err);
      }
    };

    fetchPatentData();

    return () => {
      isMounted = false;
    };
  }, [familyMembersArray, getPatentBiblio]);

  const getPriorityDate = (priorityClaims) => {
    if (!priorityClaims) return '';
    if (Array.isArray(priorityClaims)) {
      return priorityClaims[0]?.['document-id']?.['date']?.['$'] || '';
    } else {
      return priorityClaims['document-id']?.['date']?.['$'] || '';
    }
  };

  const getHighestPriorityApplicant = (applicantsArray) => {
    if (!applicantsArray.length) return '';
    const applicant = applicantsArray.find(app => app['@data-format']) || {};
    return applicant['applicant-name']?.['name']?.['$'] || '';
  };
  const getPlainSerialNo = (serialNum, countryCode, usYear) => {
    if (countryCode === "US") {
      return `${usYear}${getFamilyOriginalNumber(countryCode, serialNum)}`
    }
    return `${getFamilyOriginalNumber(countryCode, serialNum)}`
  }
  const formattedSavedFamilyData = savedFamilyArray.map((member) => {
    return {
      "Country Code": member.country_code,
      "Serial #": member.serial_num,
      "Filing Date": formatSavedDate(member.filing_date),
      "Status": member.status,
      "Pub. Number": member.pub_num,
      "Pub. Date": formatSavedDate(member.ip_date),
      "Patent #": member.pat_num,
      "Patent Date": formatSavedDate(member.patent_date),
      "Attorney Docket #": member.docket_num?.split('%')[0],
      "Applicants": member.applicants,
      "Priority Date": formatSavedDate(member.priority_date),
      "Last IDS Filed Date": "",
      "Availability": member.ref_available,
      "citedCounts": member.docket_num?.split('%')[1],
      "plainSerial#": getPlainSerialNo(member.serial_num, member.country_code, member.docket_num?.split('%')[2]),
      "plainPatent#": member.pat_num?.match(/\d+/)[0]
    };
  })
  const formattedSavedAvailRefData = savedAvailRefArray.map((member) => {
    return {
      "Publication No.": member.pub_num,
      "Kind Code": member.kind_code,
      "Publication Date": member.pub_date,
      "Serial #": member.serial_num,
      "Patentee Name": member.patentee_name,
      "Patent #": member.patent_num,
      "NplText": member.npl_text
    }
  })
  // Function to format the serial number based on the country code
  const formatSerialNumber = (countryCode, serialNumber) => {
    if (countryCode === "US") {
      // Check if the serial number starts with a 4-digit year (between 1900 and 2099)
      const yearPattern = /^(19|20)\d{2}/;
      if (yearPattern.test(serialNumber)) {
        serialNumber = serialNumber.slice(4);
      }
      return `${serialNumber.slice(0, 2)}/${serialNumber.slice(2, 5)},${serialNumber.slice(5)}`;
    } else if (countryCode === "EP") {
      return `${countryCode}${serialNumber}`;
    }
    return `${countryCode}${serialNumber}`;
  };

  const formattedData = useMemo(() => {
    const serialNumberMap = {};

    // Group records by application number
    familyMembersArray.forEach((member) => {
      const publicationReference = member['publication-reference']?.['document-id']?.[0] || {};
      const applicationReference = member['application-reference']?.['document-id'] || {};
      const parties = member['exchange-document']?.['bibliographic-data']?.['parties'] || {};
      const applicantsArray = parties['applicants']?.['applicant'] || [];
      const priorityClaims = member?.['priority-claim'];

      const pubCountry = publicationReference['country']?.['$'] || "";
      const pubDocNumber = publicationReference['doc-number']?.['$'] || "";
      const pubKind = publicationReference['kind']?.['$'] || "";
      const publicationNumber = `${pubCountry}${pubDocNumber}${pubKind}`;
      const applicationNumber = applicationReference['doc-number']?.['$'] || "N.A#";
      const appKind = applicationReference['kind']?.['$'];
      const filingDate = formatDate(applicationReference['date']?.['$'] || "N.A#");
      const pubDate = formatDate(publicationReference['date']?.['$'] || "N.A#");

      const formattedApplicationNumber = applicationNumber.replace(/^\d{4}/, '').slice(-8);
      const status = patentData[formattedApplicationNumber]?.status || "";
      const docketNo = patentData[formattedApplicationNumber]?.docketNo || "";
      // eslint-disable-next-line
      let serialNumber = `${pubCountry}${applicationNumber}${appKind}`;
      if (pubCountry === 'US') {
        // eslint-disable-next-line
        serialNumber = `${applicationNumber.slice(0, 2)}/${applicationNumber.slice(2, 5)},${applicationNumber.slice(5)}`;
      }

      const patentNo = pubDocNumber;
      // eslint-disable-next-line
      const date = pubDate;

      const highestPriorityApplicant = getHighestPriorityApplicant(applicantsArray);
      const priorityDate = getPriorityDate(priorityClaims);
      const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited']?.['citation'];
      const refCounts = referencesCited?.length;
      const availability = priorityClaims && referencesCited && refCounts ? "Ref# Available" : "Ref# Not Available";

      const record = {
        "Country Code": pubCountry,
        "Serial #": formatSerialNumber(pubCountry, applicationNumber),
        "Filing Date": filingDate,
        "Status": status,
        "Pub. Number": publicationNumber,
        "Pub. Date": pubDate,
        "Patent #": publicationNumber,
        "Patent Date": pubDate,
        "Attorney Docket #": docketNo,
        "Applicants": highestPriorityApplicant,
        "Priority Date": formatDate(priorityDate),
        "Availability": availability,
        "citedCounts": refCounts,
        "plainSerial#": applicationNumber,
        "plainPatent#": patentNo
      };

      // Check if application number is already in the map
      if (serialNumberMap[formattedApplicationNumber]) {
        const existingRecord = serialNumberMap[formattedApplicationNumber];
        if (new Date(record["Patent Date"]) > new Date(existingRecord["Patent Date"])) {
          record['Pub. Date'] = existingRecord["Patent Date"]
          record['Pub. Number'] = existingRecord["Patent #"]
          serialNumberMap[formattedApplicationNumber] = record;
        }
      } else {
        serialNumberMap[formattedApplicationNumber] = record;
      }
    });

    const sortedData = Object.values(serialNumberMap)
      .sort((a, b) => new Date(a["Patent Date"]) - new Date(b["Patent Date"]))
      .map((record, index) => ({
        ...record,
        "Sr.No": index + 1
      }));

    return sortedData;
  }, [familyMembersArray, patentData]);

  const isPlainObject = (value) => {
    return (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value) &&
      value.constructor === Object
    );
  }
  const getHighestPriorityDocId = (docIds) => {
    if (isPlainObject(docIds) === true) {
      return docIds
    }
    if (!Array.isArray(docIds)) {
      return {};
    }
    const sortedDocIds = [...docIds].sort((a, b) => {
      const priorityA = PRIORITY_ORDER.indexOf(a['@document-id-type']);
      const priorityB = PRIORITY_ORDER.indexOf(b['@document-id-type']);
      return priorityA - priorityB;
    });
    return sortedDocIds[0] || {};
  };
  const availableReferenceData = familyMembersArray.flatMap(member => {
    const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited'];
    if (!referencesCited || !Array.isArray(referencesCited['citation'])) {
      return [];
    }
    const publicationRef = member['publication-reference']?.['document-id']?.[0] || {};
    const pubDocNumberRef = publicationRef['doc-number']?.['$'] || "";
    const patentRNo = pubDocNumberRef;

    return referencesCited['citation'].map(citation => {
      const docIds = Array.isArray(citation.patcit?.['document-id']) ? citation.patcit?.['document-id'] : isPlainObject(citation.patcit?.['document-id']) ? citation.patcit?.['document-id'] : [];
      const docId = getHighestPriorityDocId(docIds);
      const nplCitations = citation['nplcit'] ? citation['nplcit'] : {};
      const nplText = nplCitations?.['text']?.['$'] || "";
      const country = docId?.['country']?.['$'] || "";
      const docNumber = docId?.['doc-number']?.['$'] || "";
      const kind = docId?.['kind']?.['$'] || "";
      const publicationDate = formatDate(docId?.['date']?.['$'] || "");
      const publicationNumber = `${country}${docNumber}${kind}`;
      const applicationReference = member['application-reference']?.['document-id'] || {};
      const applicationNumber = applicationReference['doc-number']?.['$'] || "N.A#";
      return {
        "Publication No.": publicationNumber || "N.A#",
        "Kind Code": kind || "N.A",
        "Publication Date": publicationDate || "N.A",
        "Serial #": applicationNumber || "N.A#",
        "Patentee Name": docId?.['name']?.['$'] || "N.A",
        "Patent #": patentRNo,
        "NplText": nplText !== "" ? nplText : ""
      };
    }).filter(row => row !== null);
  });
  const downloadAllFamily = () => {
    const worksheetData = combinedData.map(row => ({
      "Country Code": row['Country Code'],
      "Serial #": row['Serial #'],
      "Filing Date": row['Filing Date'],
      "Status": row['Status'],
      "Pub. Number": row['Pub. Number'],
      "Pub. Date": row['Pub. Date'],
      "Patent #": row['Patent #'],
      "Patent Date": row['Patent Date'],
      "Attorney Docket #": row['Attorney Docket #'],
      "Applicants": row['Applicants'],
      "Priority Date": row['Priority Date'],
      "Last IDS Filed Date": row['Last IDS Filed Date'],
      "Availability": row['Availability'],
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Families");

    XLSX.writeFile(workbook, "Families.xlsx");
  };
  useEffect(() => {
    if (downloadFamily) {
      downloadAllFamily();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadFamily])
  const combinedData = updateFamilyRowData.length > 0
    ? addFamilyRowData.length > 0 ? [...updateFamilyRowData, ...addFamilyRowData] : [...updateFamilyRowData]
    : formattedSavedFamilyData.length > 0 ? [...formattedSavedFamilyData, ...addFamilyRowData] : [...formattedData, ...addFamilyRowData];
  const combinedSrData = combinedData.map((item, index) => ({
    ...item,
    "Sr.No": index + 1
  }))
  useEffect(() => {
    if (availableReferenceData.length > 0) {
      dispatch(setFormattedAvailableRefData(availableReferenceData))
    }
  }, [availableReferenceData, dispatch])
  useEffect(() => {
    if (formattedData.length > 0) {
      dispatch(setFormattedFamilyData(combinedSrData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData, updateFamilyRowData, dispatch]);
  useEffect(() => {
    if (formattedSavedFamilyData.length > 0) {
      dispatch(setFormattedSaveFamilyData(formattedSavedFamilyData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(setFormattedFamilyData(combinedSrData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFamilyRowData, updateFamilyRowData])

  const headers = [
    "Sr.No",
    "Country Code",
    "Serial #",
    "Filing Date",
    "Status",
    "Pub. Number",
    "Pub. Date",
    "Patent #",
    "Patent Date",
    "Attorney Docket #",
    "Applicants",
    "Priority Date",
    "Availability",
  ];
  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const defaultData = Array.from({ length: 10 }, () => defaultRow);

  const highlightedData = combinedSrData.map((row, index) => ({
    ...row,
    "Serial #": highlightText(row["Serial #"], searchTerm, selectedColumn, 'Serial #'),
    "Pub. Number": highlightText(row["Pub. Number"], searchTerm, selectedColumn, 'Pub. Number'),
  }));
  useEffect(() => {
    if (highlightedData.length > 0) {
      dispatch(setIsLoading(false))
    }
  }, [dispatch, highlightedData])

  return (
    <div className="family-information">
      <Table headers={headers} availableReferenceData={formattedSavedAvailRefData.length > 0 ? formattedSavedAvailRefData : availableReferenceData} data={highlightedData.length > 0 ? highlightedData : []} handleUpdateFamilyRowData={handleUpdateFamilyRowData} handleAddFamilyRowData={handleAddFamilyRowData} />
    </div>
  );
};

export default FamilyInformation;
