import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../common/npl-table";
import "./npl-details.scss";
import {
  setExtractedNplData,
  setFamilyNplData,
  setFormattedNplData
} from "../../../store/reducer/searchSlice";
import { highlightText } from "../../../utils/highlight";
import Fuse from "fuse.js";
const removeTrailingComma = (str) => str.replace(/,\s*$/, "");

const formatNplString = (str) => {
  const data = {
    "Author Name": "Not Available",
    Title: "",
    "References": "",
    "Title of the Item": "",
    Date: "",
    Pages: "",
    Volume: "",
    Publisher: "",
    Link: "Not Available",
  };

  const authorMatch = str.match(/^([A-Z][A-Z\s,]*(?:and\s[A-Z][A-Z\s,]*|et\s+al\.)?)/);
  const titleMatch = str.match(/"([^"]*)"/);
  const additionalTitleMatch = str.match(/(?:^|\s)([^,]+)(?:,|$)/);
  const titleOfItemMatch = str.match(/^(?:[^"]*"[^"]*")?\s*(.*?)(?=,\s*(?:pp\.|pages?\.\d+-\d+))/i);
  const dateMatch = str.match(/(?:\b(?:\d{4}|(?:[A-Za-z]+\s\d{1,2},\s\d{4})|(?:[A-Za-z]+\s\d{4})|(?:\d{1,2}\s\(\w{4}\))|(?:\d{4}))\b)/);
  const pagesMatch = str.match(/(?:pages?\.\s*|pp\.\s*)(\d+-\d+)/);
  const linkMatch = str.match(/(https?:\/\/[^\s]+)/);
  const publisherMatch = str.match(/(?:^|\s)(?:Department|University|Press|Publication|Journal|Book|Conference|Symposium|Serial|Catalog|Publisher|City|Country|Etc)[^,]*,\s*([A-Za-z\s]+(?:,\s*[A-Z]{2})?)\b/i);

  data["Author Name"] = removeTrailingComma(authorMatch ? authorMatch[0].trim() : "Not Available");
  data.Title = removeTrailingComma(titleMatch ? titleMatch[1].trim() : (additionalTitleMatch ? additionalTitleMatch[1].trim() : ""));
  data["Title of the Item"] = removeTrailingComma(titleOfItemMatch ? titleOfItemMatch[1].trim() : "");
  data["References"] = str;
  data.Date = dateMatch ? dateMatch[0].trim() : "";
  data.Pages = pagesMatch ? pagesMatch[1].trim() : "";
  data.Link = linkMatch ? `<a href="${linkMatch[0]}" target="_blank" rel="noopener noreferrer">${linkMatch[0]}</a>` : "Not Available";
  data.Publisher = publisherMatch ? publisherMatch[1].trim() : "";

  // Fuzzy Matching for Ambiguity Correction
  const fuzzyOptions = { includeScore: true, threshold: 0.3 };
  const fuzzySearch = new Fuse([str], fuzzyOptions);

  // Fuzzy matching when regex result is insufficient
  if (!data["Author Name"] || data["Author Name"] === "Not Available") {
    const authorResults = fuzzySearch.search(/^[A-Z][a-zA-Z\s]*\b(?:and|et\s+al\.)?/);
    if (authorResults.length > 0) data["Author Name"] = authorResults[0].item.trim();
  }

  if (!data.Title && !titleMatch) {
    const titleResults = fuzzySearch.search(/"[^"]*"|^[^\.,]+/);
    if (titleResults.length > 0) data.Title = titleResults[0].item.trim();
  }

  if (!data.Publisher && !publisherMatch) {
    const publisherResults = fuzzySearch.search(/(?:Department|University|Press|Publication|Journal|Conference|Symposium)[^,]*\b/);
    if (publisherResults.length > 0) data.Publisher = publisherResults[0].item.trim();
  }

  return data;
};

const NplDetails = ({ activeTab, searchTerm, selectedColumn, handleAdd, handleEdit, src1, src2 }) => {
  const { familyBiblio } = useSelector((state) => state.family);
  const { extractedData: dataTable } = useSelector((state) => state.search);
  const discardedNplData = useSelector((state) => state.search.discardedNplData);
  const finalDiscardedNplData = discardedNplData.length === 0 ? [] : discardedNplData;
  const selectedNplData = useSelector((state) => state.search.selectedNplData);
  const isNPLFileUploaded = useSelector((state) => state.search.isNPLFileUploaded);
  const formattedReferenceData = useSelector((state) => state.references.formattedReferenceData);
  const extractedPdfNplData = useSelector((state) => state.search.extractedPdfNplData);
  const extractedNplData = formattedReferenceData.filter(
    (item) => item["IDS Form Section Heading"] === "NON-PATENT LITERATURE"
  );
  const [addRowData, setAddRowData] = useState([])
  const [updateRowData, setUpdateRowData] = useState([])
  const handleUpdateRowData = (data) => {
    setUpdateRowData(data)
  }
  const handleAddRowData = (data) => {
    setAddRowData(data)
  }
  const extractedFileNplData = useSelector((state) => state.references.extractedFileNplData);
  const formattedExtractedNplData = extractedFileNplData.map((member) => {
    return {
      "References": member["References"],
      "IDS Form Section Heading": member["IDS Form Section Heading"]
    }
  })
  const [selectedAuthors, setSelectedAuthors] = useState({});
  const dispatch = useDispatch();
  const familyMembers =
    familyBiblio?.["ops:world-patent-data"]?.["ops:patent-family"]?.[
    "ops:family-member"
    ] || [];
  const familyMembersArray = Array.isArray(familyMembers)
    ? familyMembers
    : [familyMembers];

  const nplStringsFromFamily = familyMembersArray.flatMap((member) => {
    const referencesCited =
      member?.["exchange-document"]?.["bibliographic-data"]?.[
      "references-cited"
      ];
    if (!referencesCited || !Array.isArray(referencesCited.citation)) {
      return [];
    }

    return referencesCited.citation.flatMap((citation) => {
      if (citation?.nplcit) {
        return citation.nplcit.text?.["$"] ? [citation.nplcit.text["$"]] : [];
      }
      return [];
    });
  });

  const nplStringsFromExtractedData = (dataTable || []).flatMap((row) => {
    const type =
      row?.["uscom:TableRow"][0]?.["uscom:TableHeaderCell"]?.[
      "uscom:Heading"
      ] || row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell'] || ""
    if (type === "NON-PATENT LITERATURE DOCUMENTS") {
      return row["uscom:TableRow"].slice(2).flatMap((dataRow) => {
        const dataCells = dataRow?.["uscom:TableDataCell"] || [];
        if (dataCells.length > 2 && dataCells[2]?.["uscom:TextInput"]) {
          return [dataCells[2]["uscom:TextInput"]];
        }
        return [];
      });
    }
    return [];
  });

  const formattedFamilyData = nplStringsFromFamily.map(formatNplString);
  const formattedExtractedData =
    nplStringsFromExtractedData.map(formatNplString);

  const handleCheckboxChange = (authorName) => {
    setSelectedAuthors((prevState) => ({
      ...prevState,
      [authorName]: !prevState[authorName],
    }));
  };

  const addCheckboxToAuthorName = (formattedData) => {
    return formattedData.map((row) => ({
      ...row,
      "Author Name": (
        <label className="author-checkbox-label">
          <input
            type="checkbox"
            checked={selectedAuthors[row["Author Name"]] || false}
            onChange={() => handleCheckboxChange(row["Author Name"])}
          />
          <span>{row["Author Name"]}</span>
        </label>
      ),
    }));
  };

  const headers = [
    "Sr.No",
    "References"
  ];

  const defaultRow = headers.reduce(
    (acc, header) => ({ ...acc, [header]: "" }),
    {}
  );

  let data;
  const formattedExtractedPdfNplData = extractedPdfNplData.length > 0 ? extractedPdfNplData.map((item) => ({
    "References": item["Publication / Application Number"]
  })) : []
  if (activeTab === "initialReferences") {
    data = formattedExtractedData.length > 0 ? formattedExtractedData : extractedPdfNplData.length > 0 ? [...formattedExtractedPdfNplData] : [];
  } else if (activeTab === "references") {
    data = formattedFamilyData.length > 0 ? formattedFamilyData : isNPLFileUploaded === true ? [...formattedFamilyData, ...formattedExtractedNplData] : [];
  } else {
    data = finalDiscardedNplData.length > 0 ? finalDiscardedNplData : selectedNplData.length !== 0 ? selectedNplData : isNPLFileUploaded === true ? [...formattedFamilyData, ...formattedExtractedNplData] : formattedFamilyData;
  }
  const combinedData = updateRowData.length > 0 ? addRowData.length > 0 ? [...updateRowData, ...addRowData] : [...updateRowData] : [...data, ...addRowData]
  const highlightedData = combinedData.map((item, index) => ({
    ...item,
    "Sr.No": index + 1,
    "References": highlightText(
      item["References"] || '',
      searchTerm,
      selectedColumn,
      'References'
    ),
    Selected: false,
  }));
  useEffect(() => {
    dispatch(setFormattedNplData(combinedData))
  }, [activeTab, dispatch, updateRowData])
  useEffect(() => {
    dispatch(setExtractedNplData(formattedExtractedData));
  }, [dataTable, dispatch]);
  useEffect(() => {
    dispatch(setFamilyNplData(formattedFamilyData));
  }, [familyBiblio, dispatch]);

  return (
    <div className="details-container">
      {data.length === 0 ? <></> : <> <div className="label d-flex flex-row align-items-center">Non-Patent Literature Documents {activeTab === 'references' && selectedNplData.filter((item) => item.Selected === true).length > 0 && (<span className="ms-1 selected-npl-class">{selectedNplData.filter((item) => item.Selected === true).length} NPLs Selected</span>)}
        <div className='icons' style={{ marginRight: "0px" }}>
          <img src={src2} alt="Table Edit" onClick={handleEdit} className="icon" title='Edit the Fields in the Npl Table' />
          <img src={src1} alt="Table Add" onClick={() => handleAdd("npl-table")} className="icon" title='Add New Row in the Npl Table' />
        </div>
      </div>
        <div className="data-list">
          <Table
            headers={headers}
            formattedData={highlightedData}
            renderAsHtml
            activeTab={activeTab}
            handleUserRowData={handleAddRowData} handleUpdateRowData={handleUpdateRowData}
          />
        </div></>}
    </div>
  );
};

export default NplDetails;
