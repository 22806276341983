import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { referencesApi } from '../action/referencesApi';

export const revertAllReferencesData = createAction("REVERT_ALL_REF_DATA");

// Async thunk for saving initial references
export const saveReferences = createAsyncThunk(
  'References/save',
  async (data, thunkAPI) => {
    try {
      const response = await thunkAPI.dispatch(
        referencesApi.endpoints.createReference.initiate(data)
      );

      return response;
    } catch (error) {
      console.error('API Call Failed:', error);
      return thunkAPI.rejectWithValue(error.message || 'Failed to save data');
    }
  }
);

const initialState = {
  data: [],
  status: 'idle',
  error: null,
  formattedReferenceData: [],
  formattedDbReferenceData: [],
  extractedFileNplData: []
};

// Slice definition
const referencesSlice = createSlice({
  name: 'References',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(revertAllReferencesData, () => initialState)
      .addCase(saveReferences.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveReferences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(saveReferences.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to save initial references';
      });
  },
  reducers: {
    setFormattedRefData: (state, action) => {
      state.formattedReferenceData = action.payload;
    },
    setFormattedDbRefData: (state, action) => {
      state.formattedDbReferenceData = action.payload;
    },
    setExtractedFileNplData: (state, action) => {
      state.extractedFileNplData = action.payload;
    },
  },

});

export const { setFormattedRefData, setFormattedDbRefData, setExtractedFileNplData } = referencesSlice.actions;

export default referencesSlice.reducer;
