import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import Table from '../../common/references-table';
import './citation-matrix.scss';
import { formatDate, PRIORITY_ORDER, formatSavedDate, usFirstSort } from '../../../util/utils';
import { setFormattedRefData, setFormattedDbRefData } from '../../../store/reducer/referencesSlice';
import { setExtractedFileNplData } from '../../../store/reducer/referencesSlice';
import { useLazyGetExtractedRefDataQuery } from '../../../store/action/familyBiblioApi';
import { useGetSavedRefByUserQuery } from '../../../store/action/referencesApi';
import { highlightText } from '../../../utils/highlight';
import { useLocation } from "react-router-dom";
import { getOriginalNumber } from '../../../util/utils';
import { useGetUsptoReferencesMutation } from '../../../store/action/patentBiblioApi';
const CitationMatrix = ({ downloadReferences, searchTerm, selectedColumn, setIsExportPopupOpen }) => {
  const { state } = useLocation();
  const { number: pubNum } = state || {};
  const [getUsptoReferences] = useGetUsptoReferencesMutation();
  const { familyBiblio } = useSelector((state) => state.family);
  const { extractedExcelData } = useSelector((state) => state.search);
  const extractedNplFileUploadData = useSelector((state) => state.search.extractedNplFileUploadData);
  const { addRefData } = useSelector((state) => state.search);
  const number = useSelector((state) => state.search.number)
  const isNPLFileUploaded = useSelector((state) => state.search.isNPLFileUploaded);
  const userNumber = sessionStorage.getItem("userId")
  const { data } = useGetSavedRefByUserQuery({ userId: userNumber, num: number !== '' ? number : pubNum })
  const savedReferencesArray = data ? [...data] : []
  const [formattedNameData, setFormattedNameData] = useState([])
  const [formattedDbNameData, setFormattedDbNameData] = useState([])
  const [addRowData, setAddRowData] = useState([])
  const [updateRowData, setUpdateRowData] = useState([])
  // const [formattedData, setFormattedData] = useState([]);
  const formattedFamilyData = useSelector((state) => state.family.formattedFamilyData)
  const formattedSaveFamilyData = useSelector((state) => state.family.formattedSaveFamilyData)
  const formattedSerialNumbers = formattedSaveFamilyData.length > 0 ? formattedSaveFamilyData.map((member) => `${member['Country Code']} ${getOriginalNumber(member['Country Code'], member['Serial #'])}`).sort(usFirstSort) : formattedFamilyData.map((member) => `${member['Country Code']} ${getOriginalNumber(member['Country Code'], member['Serial #'])}`).sort(usFirstSort)

  const handleUpdateRowData = (data) => {
    setUpdateRowData(data)
  }
  const handleAddRowData = (data) => {
    setAddRowData(data)
  }
  const dispatch = useDispatch();

  const getHighestPriorityDocId = (docIds) => {
    if (!Array.isArray(docIds)) {
      return {};
    }
    const sortedDocIds = [...docIds].sort((a, b) => {
      const priorityA = PRIORITY_ORDER.indexOf(a['@document-id-type']);
      const priorityB = PRIORITY_ORDER.indexOf(b['@document-id-type']);
      return priorityA - priorityB;
    });
    return sortedDocIds[0] || {};
  };
  const additionalReferencesArray = addRefData
    ? addRefData.map((number) =>
      typeof number === 'string' ? number.slice(0, -2) : number
    )
    : [];

  const additonalRefKindCodeData = addRefData
    ? addRefData.map((number) =>
      typeof number === 'string' ? number.slice(-2) : ""
    )
    : [];

  const additionalRefObject = addRefData
    ? addRefData.map((item, index) => ({
      reference: typeof item === 'string' ? item : "N.A#",
      kind: additonalRefKindCodeData[index] || "N.A"
    }))
    : [];

  const familyMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member'];

  const familyMembersArray = Array.isArray(familyMembers) ? familyMembers : (familyMembers ? [familyMembers] : []);

  const extractedFormattedExcelData = extractedExcelData
    ? extractedExcelData.map((number) =>
      typeof number === 'string' ? number.slice(0, -2) : number
    )
    : [];

  const extractedKindCodeData = extractedExcelData
    ? extractedExcelData.map((number) =>
      typeof number === 'string' ? /^\d/.test(number.slice(-2)) === true ? number.slice(-1) : number.slice(-2) : ""
    )
    : [];

  const extractedExcelObject = extractedExcelData
    ? extractedExcelData.map((item, index) => ({
      reference: typeof item === 'string' ? item : "N.A#",
      kind: extractedKindCodeData[index] || "N.A"
    }))
    : [];
  const [excelData, setExcelData] = useState([])
  const [usptoData, setUsptoData] = useState([]);
  const [addRef, setAddRef] = useState([])
  const [getExtractedRefData] = useLazyGetExtractedRefDataQuery()
  useEffect(() => {
    const fetchUsptoRefData = async () => {
      const serialNumbers = formattedFamilyData.filter((member) => member['Country Code'] === "US").map((member) => {
        return member['plainSerial#']?.slice(-8)
      })
      const requests = serialNumbers.map(serialNumber =>
        getUsptoReferences({ criteria: `patentApplicationNumber:${serialNumber}`, start: 0, rows: 100 }).unwrap()
      );
      try {
        await Promise.all(requests).then((results) => {
          setUsptoData(results)
        })
      }
      catch {
        console.log('Error Retrieving USPTO References')
      }
    }
    const fetchExtractedRefData = async () => {
      const requests = extractedFormattedExcelData.map(async (member) => {
        const { data } = await getExtractedRefData({ type: "publication", format: "docdb", number: member })
        return data;
      })
      try {
        const results = await Promise.all(requests);
        setExcelData(results);
      } catch (err) {
        console.error('Error fetching extracted references data:', err);
      }
    }
    const fetchAdditionalRefData = async () => {
      const requests = additionalReferencesArray.map(async (member) => {
        const { data } = await getExtractedRefData({ type: "publication", format: "docdb", number: member })
        return data;
      })
      try {
        await Promise.all(requests).then((results) => {
          setAddRef(results)
        })
      } catch (err) {
        console.error('Error fetching extracted references data:', err);
      }
    }
    fetchExtractedRefData()
    fetchAdditionalRefData()
    fetchUsptoRefData()
  }, [])

  const filteredUsptoData = usptoData?.filter((member) => member['response']?.numFound !== 0)

  const allUsptoRefData = filteredUsptoData?.flatMap(item => item.response.docs)

  const formattedAddRefData = additionalRefObject
    .map((member, index) => {
      const addRefDataArray = addRef[index]?.["ops:world-patent-data"]?.["exchange-documents"]?.[
        "exchange-document"
      ];
      const addRefDataDetails = Array.isArray(addRefDataArray)
        ? addRefDataArray.filter((item) => item['@kind'] === member['kind'])
        : addRefDataArray;

      const addRefDataFormatted = Array.isArray(addRefDataDetails)
        ? addRefDataDetails[0]
        : addRefDataDetails;

      const applicationReference = addRefDataFormatted?.["bibliographic-data"]?.['application-reference']?.['document-id'] || [];

      const publicationRef = addRefDataFormatted?.["bibliographic-data"]?.['publication-reference']?.['document-id'] || [];

      const parties = addRefDataFormatted?.["bibliographic-data"]?.['parties'] || [];

      const countryCode = addRefDataFormatted?.["@country"] || "";

      const inventors = parties?.['inventors']?.['inventor'] || [];

      const inventorObject = Array.isArray(inventors) === false ? inventors : {};

      const applicants = parties?.['applicants']?.['applicant'] || [];

      const applicantObject = Array.isArray(applicants) === false ? applicants : {};

      const inventorName =
        Object.keys(inventorObject).length === 0
          ? inventors?.find(
            (doc) => doc['@sequence'] === '1' && doc['@data-format'] === 'original'
          )?.['inventor-name']?.['name']?.["$"]
          : inventorObject?.['inventor-name']?.['name']?.["$"];

      const applicantName =
        Object.keys(applicantObject).length === 0
          ? applicants?.find(
            (doc) => doc['@sequence'] === '1' && doc['@data-format'] === 'original'
          )?.['applicant-name']?.['name']?.["$"]
          : applicantObject?.['applicant-name']?.['name']?.["$"];

      const patenteeName = countryCode === "US" ? inventorName : applicantName;

      const publicationDate = publicationRef?.find((doc) => doc["@document-id-type"] === "docdb")?.["date"]?.["$"];

      const formatPubDate = formatDate(publicationDate);

      const originalDocNumber = applicationReference?.find(
        (doc) => doc["@document-id-type"] === "docdb"
      )?.["doc-number"]?.["$"];

      return {
        Selected: false,
        "Serial #": originalDocNumber || "N.A#",
        References: member['reference'] || "N.A#",
        "Kind Code": member['kind'] || "N.A",
        "Issued / Pub. Date": formatPubDate || "N.A",
        "Patentee Name": patenteeName || "N.A",
      };
    })
    .filter((row) =>
      Object.values(row).some(
        (value) => value !== "N.A#" && value !== "N.A"
      )
    );
  const classifyPatentNumber = (patentNumber) => {
    const patentStr = patentNumber.toString().slice(0, -2).slice(2);
    if (patentStr.length >= 9) {
      return "U.S.PATENT APPLICATION PUBLICATIONS"
    } else {
      return "U.S.PATENTS"
    }
  }
  const classifyPatentNumberWithoutCode = (patentNumber) => {
    const countryCode = patentNumber.slice(0, 2);
    if (countryCode !== "US") {
      return "FOREIGN PATENT DOCUMENTS"
    }
    const patentStr = patentNumber.toString().slice(0, -2).slice(2);
    if (patentStr.length >= 9) {
      return "U.S.PATENT APPLICATION PUBLICATIONS"
    } else {
      return "U.S.PATENTS"
    }
  }
  const classifyUsptoNumber = (patentNumber) => {
    if (patentNumber.length >= 9) {
      return "U.S.PATENT APPLICATION PUBLICATIONS"
    } else {
      return "U.S.PATENTS"
    }
  }
  const formattedUsptoRefData = allUsptoRefData?.map((member, index) => {
    const patentRef = member?.['citedDocumentIdentifier'];
    const patentRefName = member?.['inventorNameText'];
    const patentRefDate = member?.['createDateTime']
    const patentSerialNo = member?.['patentApplicationNumber']
    const regex = /^US (\d+) (\w\d)$/;
    const match = patentRef?.match(regex);
    if (match) {
      const patentNumber = match[1];
      const kindCode = match[2];
      return {
        "Selected": false,
        "Serial #": `YYYY${patentSerialNo}`,
        "References": patentNumber && kindCode ? `US${patentNumber}` : "N.A",
        "Kind Code": kindCode ? kindCode : "N.A",
        "Issued / Pub. Date": patentRefDate ? patentRefDate?.slice(0, 10) : "N.A",
        "Patentee Name": patentRefName ? patentRefName : "N.A",
        "IDS Form Section Heading": classifyUsptoNumber(patentNumber),
      }
    }
  })

  const seenUsptoRef = new Map();

  const filteredFormattedUsptoRefData = formattedUsptoRefData ? formattedUsptoRefData?.filter((item) => item !== undefined).map((item) => {

    const referenceKey = item['References'];

    if (seenUsptoRef.has(referenceKey)) {

      const existingData = seenUsptoRef.get(referenceKey);

      let serialKeyIndex = 1;
      while (existingData.hasOwnProperty(`Serial #${serialKeyIndex}`)) {
        serialKeyIndex++;
      }

      existingData[`Serial #${serialKeyIndex}`] = item["Serial #"];
      return {};
    }

    const newData = {
      "Selected": false,
      "Serial #": item["Serial #"],
      "References": item["References"],
      "Kind Code": item["Kind Code"],
      "Issued / Pub. Date": item["Issued / Pub. Date"],
      "Patentee Name": item["Patentee Name"],
      "IDS Form Section Heading": item["IDS Form Section Heading"],
    };

    seenUsptoRef.set(referenceKey, newData);

    return newData;

  }).filter(item => Object.keys(item).length > 0) : []

  const formattedExcelData = extractedExcelObject
    .map((member, index) => {
      const excelDataArray = excelData[index]?.["ops:world-patent-data"]?.["exchange-documents"]?.[
        "exchange-document"
      ];
      const excelDataDetails = Array.isArray(excelDataArray)
        ? excelDataArray.filter((item) => item['@kind'] === member['kind'])
        : excelDataArray;

      const excelDataFormatted = Array.isArray(excelDataDetails)
        ? excelDataDetails[0]
        : excelDataDetails;

      const applicationReference = excelDataFormatted?.["bibliographic-data"]?.['application-reference']?.['document-id'] || [];

      const publicationRef = excelDataFormatted?.["bibliographic-data"]?.['publication-reference']?.['document-id'] || [];

      const parties = excelDataFormatted?.["bibliographic-data"]?.['parties'] || [];

      const countryCode = excelDataFormatted?.["@country"] || "";

      const inventors = parties?.['inventors']?.['inventor'] || [];

      const inventorObject = Array.isArray(inventors) === false ? inventors : {};

      const applicants = parties?.['applicants']?.['applicant'] || [];

      const applicantObject = Array.isArray(applicants) === false ? applicants : {};

      const inventorName =
        Object.keys(inventorObject).length === 0
          ? inventors?.find(
            (doc) => doc['@sequence'] === '1' && doc['@data-format'] === 'original'
          )?.['inventor-name']?.['name']?.["$"]
          : inventorObject?.['inventor-name']?.['name']?.["$"];

      const applicantName =
        Object.keys(applicantObject).length === 0
          ? applicants?.find(
            (doc) => doc['@sequence'] === '1' && doc['@data-format'] === 'original'
          )?.['applicant-name']?.['name']?.["$"]
          : applicantObject?.['applicant-name']?.['name']?.["$"];

      const patenteeName = countryCode === "US" ? inventorName : applicantName;

      const publicationDate = publicationRef?.find((doc) => doc["@document-id-type"] === "docdb")?.["date"]?.["$"];

      const formatPubDate = formatDate(publicationDate);

      const originalDocNumber = applicationReference?.find(
        (doc) => doc["@document-id-type"] === "docdb"
      )?.["doc-number"]?.["$"];
      const publicationNumber = `${member['reference']}`;
      const publicationType = countryCode === "US"
        ? classifyPatentNumber(publicationNumber)
        : "FOREIGN PATENT DOCUMENTS";

      return {
        "Selected": false,
        "References": member['kind']?.length === 1 ? member['reference']?.slice(0, -1) : member['reference']?.slice(0, -2) || "N.A#",
        "Kind Code": member['kind'] || "N.A",
        "Issued / Pub. Date": formatPubDate || "N.A",
        "Patentee Name": patenteeName || "N.A",
        "IDS Form Section Heading": countryCode === "" ? classifyPatentNumberWithoutCode(publicationNumber) : publicationType,
        "countryCode": countryCode,
        "pubNum": publicationNumber
      };
    })
    .filter((row) =>
      Object.values(row).some(
        (value) => value !== "N.A#" && value !== "N.A"
      )
    );
  const formattedNplListData = extractedNplFileUploadData.map((member) => {
    return {
      "References": member || "N.A#",
      "IDS Form Section Heading": 'NON-PATENT LITERATURE'
    }
  })


  const fetchPatenteeData = async (patentNumber, cachedData) => {
    if (cachedData !== undefined) {
      return cachedData;
    }

    if (patentNumber) {
      try {
        const { data } = await getExtractedRefData({ type: "publication", format: "docdb", number: patentNumber });

        if (data?.['ops:world-patent-data']?.['exchange-documents']?.['exchange-document']?.['bibliographic-data']?.['parties']?.['applicants']?.['applicant']) {
          const applicants = data['ops:world-patent-data']['exchange-documents']['exchange-document']['bibliographic-data']['parties']['applicants']['applicant'];
          const applicantArray = Array.isArray(applicants) ? applicants : [applicants];

          const originalApplicant = applicantArray.find(applicant => applicant['@data-format'] === 'original');

          if (originalApplicant) {
            const patenteeName = originalApplicant['applicant-name']?.['name']?.['$'];
            return patenteeName || "N.A";
          }
        }
        return "N.A";
      } catch (error) {
        console.error('Error fetching extracted references data:', error);
        return "N.A";
      }
    }
    return "N.A";
  };


  const formattedData = useMemo(() => {
    const seenReferences = new Map();
    const finalData = familyMembersArray.flatMap((member) => {
      const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited'];
      if (!referencesCited || !Array.isArray(referencesCited['citation'])) {
        return [];
      }
      const applicationReference = member['application-reference']?.['document-id'] || {};
      const applicationNumber = applicationReference['doc-number']?.['$'] || "N.A#";

      return referencesCited['citation'].map((citation) => {
        const docIds = Array.isArray(citation.patcit?.['document-id']) ? citation.patcit?.['document-id'] : [];
        const docId = getHighestPriorityDocId(docIds);
        const country = docId?.['country']?.['$'] || "";
        const docNumber = docId?.['doc-number']?.['$'] || "";
        const kind = docId?.['kind']?.['$'] || "";
        const publicationDate = formatDate(docId?.['date']?.['$'] || "");
        const publicationNumber = `${country}${docNumber}${kind}`;
        const publicationType = country === "US"
          ? classifyPatentNumber(publicationNumber)
          : "FOREIGN PATENT DOCUMENTS";
        const referenceKey = `${country}${docNumber}`;
        if (seenReferences.has(referenceKey)) {
          const existingData = seenReferences.get(referenceKey);

          let serialKeyIndex = 1;
          while (existingData.hasOwnProperty(`Serial #${serialKeyIndex}`)) {
            serialKeyIndex++;
          }

          existingData[`Serial #${serialKeyIndex}`] = applicationNumber;
          return {};
        }

        const newData = {
          "Selected": false,
          "Serial #": applicationNumber || "N.A#",
          "References": `${country}${docNumber}` || "N.A#",
          "Kind Code": kind || "N.A",
          "Issued / Pub. Date": publicationDate || "N.A",
          "Patentee Name": docId?.['name']?.['$'] || "N.A",
          "IDS Form Section Heading": publicationType,
        };


        seenReferences.set(referenceKey, newData);

        return newData;
      }).filter(item => Object.keys(item).length > 0);

    });
    return finalData.filter(row => {
      return !(
        (row['References'] === 'N.A' || row['References'] === 'N.A#') &&
        (row['Kind Code'] === 'N.A' || row['Kind Code'] === 'N.A#') &&
        (row['Issued / Pub. Date'] === 'N.A' || row['Issued / Pub. Date'] === 'N.A#') &&
        (row['Patentee Name'] === 'N.A')
      );
    });
  }, [familyMembersArray]);

  const formattedDbData = useMemo(() => {
    const finalData = familyMembersArray.flatMap((member) => {
      const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited'];
      if (!referencesCited || !Array.isArray(referencesCited['citation'])) {
        return [];
      }
      const applicationReference = member['application-reference']?.['document-id'] || {};
      const applicationNumber = applicationReference['doc-number']?.['$'] || "N.A#";

      return referencesCited['citation'].map((citation) => {
        const docIds = Array.isArray(citation.patcit?.['document-id']) ? citation.patcit?.['document-id'] : [];
        const docId = getHighestPriorityDocId(docIds);
        const country = docId?.['country']?.['$'] || "";
        const docNumber = docId?.['doc-number']?.['$'] || "";
        const kind = docId?.['kind']?.['$'] || "";
        const publicationDate = formatDate(docId?.['date']?.['$'] || "");
        const publicationNumber = `${country}${docNumber}${kind}`;
        const publicationType = country === "US"
          ? classifyPatentNumber(publicationNumber)
          : "FOREIGN PATENT DOCUMENTS";

        return {
          "Selected": false,
          "Serial #": applicationNumber || "N.A#",
          "References": `${country}${docNumber}` || "N.A#",
          "Kind Code": kind || "N.A",
          "Issued / Pub. Date": publicationDate || "N.A",
          "Patentee Name": docId?.['name']?.['$'] || "N.A",
          "IDS Form Section Heading": publicationType,
        };
      })
    });

    return finalData.filter(row => {
      return !(
        (row['References'] === 'N.A' || row['References'] === 'N.A#') &&
        (row['Kind Code'] === 'N.A' || row['Kind Code'] === 'N.A#') &&
        (row['Issued / Pub. Date'] === 'N.A' || row['Issued / Pub. Date'] === 'N.A#') &&
        (row['Patentee Name'] === 'N.A')
      );
    });
  }, [familyMembersArray]);

  useEffect(() => {
    const updatePatenteeNames = async () => {
      const updatedData = formattedData.map(async (row) => {
        if (row['Patentee Name'] === "N.A") {
          const updatedPatenteeName = await fetchPatenteeData(row['References']);
          return {
            ...row,
            'Patentee Name': updatedPatenteeName,
          };
        }
        return row;
      });
      await Promise.all(updatedData).then((result) => {
        dispatch(setFormattedRefData(result));
        setFormattedNameData(result)
      });
    };
    updatePatenteeNames();
  }, []);

  useEffect(() => {
    const updatePatenteeNames = async () => {
      const updatedData = formattedDbData.map(async (row) => {
        if (row['Patentee Name'] === "N.A") {
          const updatedPatenteeName = await fetchPatenteeData(row['References']);
          return {
            ...row,
            'Patentee Name': updatedPatenteeName,
          };
        }
        return row;
      });
      await Promise.all(updatedData).then((result) => {
        dispatch(setFormattedDbRefData(result));
        setFormattedDbNameData(result)
      });
    };
    updatePatenteeNames();
  }, []);

  const seenSavedReferences = new Map();

  const formattedSavedReferencesData = savedReferencesArray.map((member) => {

    const referenceKey = member.ref_num;

    if (seenSavedReferences.has(referenceKey)) {

      const existingData = seenSavedReferences.get(referenceKey);

      let serialKeyIndex = 1;

      while (existingData.hasOwnProperty(`Serial #${serialKeyIndex}`)) {
        serialKeyIndex++;
      }

      existingData[`Serial #${serialKeyIndex}`] = member.serial_num;

      return {};
    }

    const newData = {
      "Selected": false,
      "Serial #": member.serial_num,
      "References": member.ref_num,
      "Kind Code": member.kind_code,
      "Issued / Pub. Date": formatSavedDate(member.ip_date),
      "Patentee Name": member.patentee_name,
      "IDS Form Section Heading": member.ref_num.slice(0, 2) === "US"
        ? classifyPatentNumber(member.ref_num)
        : "FOREIGN PATENT DOCUMENTS",
    };

    seenSavedReferences.set(referenceKey, newData);

    return newData;

  }).filter(item => Object.keys(item).length > 0);

  const formattedSavedRedReferencesData = savedReferencesArray.map((member) => {
    const redundantData = {
      "Selected": false,
      "Serial #": member.serial_num,
      "References": member.ref_num,
      "Kind Code": member.kind_code,
      "Issued / Pub. Date": formatSavedDate(member.ip_date),
      "Patentee Name": member.patentee_name,
      "IDS Form Section Heading": member.ref_num.slice(0, 2) === "US"
        ? classifyPatentNumber(member.ref_num)
        : "FOREIGN PATENT DOCUMENTS",
    };
    return redundantData;
  })
  const headers = [
    "Sr.No",
    "References",
    "Kind Code",
    "Issued / Pub. Date",
    "Patentee Name",
    ...formattedSerialNumbers
    // "Serial #",
  ];
  const combinedData = updateRowData.length > 0 ? addRowData.length > 0 ? [...updateRowData, ...addRowData] : [...updateRowData] : formattedSavedReferencesData.length > 0 ? [...formattedSavedReferencesData, ...addRowData] : formattedNameData.length > 0 ? [...formattedNameData, ...formattedAddRefData, ...formattedExcelData, ...filteredFormattedUsptoRefData, ...addRowData] : [...formattedData, ...formattedAddRefData, ...formattedExcelData, ...filteredFormattedUsptoRefData, ...addRowData];
  const combinedDbData = updateRowData.length > 0 ? addRowData.length > 0 ? [...updateRowData, ...addRowData] : [...updateRowData] : formattedSavedReferencesData.length > 0 ? [...formattedSavedRedReferencesData, ...addRowData] : formattedDbNameData.length > 0 ? [...formattedDbNameData, ...formattedAddRefData, ...formattedExcelData, ...filteredFormattedUsptoRefData, ...addRowData] : [...formattedDbData, ...formattedAddRefData, ...formattedExcelData, ...filteredFormattedUsptoRefData, ...addRowData];
  const highlightedData = combinedData.map((item, index) => ({
    ...item,
    "Sr.No": index + 1,
    "References": highlightText(
      item["References"] || '',
      searchTerm,
      selectedColumn,
      'References'
    ),

  }));
  useEffect(() => {
    if (formattedNplListData.length > 0) {
      dispatch(setExtractedFileNplData(formattedNplListData))
    }
  }, [formattedNplListData])
  useEffect(() => {
    dispatch(setFormattedRefData(combinedData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedData, updateRowData, dispatch]);
  useEffect(() => {
    dispatch(setFormattedDbRefData(combinedDbData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedDbData, updateRowData, dispatch]);

  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const defaultData = Array.from({ length: 4 }, () => defaultRow);
  return (
    <div className="citation-matrix">
      {(<Table headers={headers} formattedData={highlightedData.length > 0 ? highlightedData : defaultData} handleUserRowData={handleAddRowData} handleUpdateRowData={handleUpdateRowData} downloadReferences={downloadReferences} setIsExportPopupOpen={setIsExportPopupOpen} />)}
    </div>
  );
};

export default CitationMatrix;