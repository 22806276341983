import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const patentBiblioApi = createApi({
  reducerPath: "patentBiblioApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),

  endpoints: (builder) => ({
    getPatentBiblio: builder.mutation({
      query: ({ criteria, start, rows }) => ({
        url: 'patent/patentBiblio',
        method: 'POST',
        body: { criteria, start, rows },
      }),
    }),
    getUsptoReferences: builder.mutation({
      query: ({ criteria, start, rows }) => ({
        url: 'patent/patentReferences',
        method: 'POST',
        body: { criteria, start, rows },
      }),
    })
  }),
});

export const { useGetPatentBiblioMutation, useGetUsptoReferencesMutation } = patentBiblioApi;
export default patentBiblioApi;
