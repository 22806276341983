import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetPatentBiblioMutation } from "../../store/action/patentBiblioApi";
import { useGetEpoBiblioQuery } from "../../store/action/epoBiblioApi";
import {
  setPatentBiblioData,
  setLoading,
  setError,
} from "../../store/reducer/patentBiblioSlice";
import { useLocation } from "react-router-dom";
import "./index.scss";
import { saveAdmin, setAdminData } from "../../store/reducer/adminSlice";
import { setUsPublNum } from "../../store/reducer/searchSlice";
import { saveTopContent, setTopContentData } from "../../store/reducer/topContentSlice";
import TableEdit from "../../assets/images/table-edit-icon.png";
import { setCanTopEdit } from "../../store/reducer/searchSlice";
import checkBtn from "../../assets/images/checkBtn.jpg"
import xBtn from "../../assets/images/xBtn.jpg"
import { useEditTopContentMutation, useGetSavedTopContentDataByUserQuery } from "../../store/action/topContentApi";
import { formatSavedDate, convertDateToISO } from "../../util/utils";
const TopContent = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { type, number } = state || {};
  const searchParams = useSelector((state) => state.search);
  const canTopEdit = useSelector((state) => state.search.canTopEdit);
  const reduxTopContentData = useSelector((state) => state.topContent.data);
  const adminDataList = useSelector((state) => state.admin.data);
  const { patentBiblioData, loading, error } = useSelector(
    (state) => state.patentBiblio
  );
  const plainSearchNum = useSelector((state) => state.search.plainSearchNum);
  const { docketNumber } = useSelector((state) => state.search);
  const radioOption = useSelector((state) => state.search.radioOption);
  const popupNewIdsNumber = useSelector((state) => state.search.popupNewIdsNumber);
  const userId = parseInt(sessionStorage.getItem("userId"), 10);
  const { data: topContentSavedData } = useGetSavedTopContentDataByUserQuery({ userId: sessionStorage.getItem("userId"), id: searchParams.number })
  const savedTopContentData = topContentSavedData?.length > 0 ? [...topContentSavedData] : []
  const savedFormattedTopContentData = savedTopContentData[0];
  const formattedTopContentObject = {
    "Serial #": savedFormattedTopContentData?.serial_num,
    "Attorney Docket #": savedFormattedTopContentData?.docket_num,
    "Filing Date": formatSavedDate(savedFormattedTopContentData?.filing_date),
    "Applicant": savedFormattedTopContentData?.applicant_name,
    "Title of Invention": savedFormattedTopContentData?.title,
    "Status": savedFormattedTopContentData?.status,
  }
  const [apiCalled, setApiCalled] = useState(false)
  const [editTopContent] = useEditTopContentMutation();
  const [editObject, setEditObject] = useState({})
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }
  if (isNaN(userId)) {
    throw new Error('Invalid userId');
  }
  const { data: epoBiblioData, error: epoError } = useGetEpoBiblioQuery(
    {
      type: type ? type : searchParams.type,
      format: "docdb",
      number: number ? number : searchParams.number,
    },
    { skip: !(searchParams.number) }
  );
  const { data: epoBiblioData1 } = useGetEpoBiblioQuery(
    {
      type: type ? type : searchParams.type,
      format: "docdb",
      number: searchParams.number1 !== "" ? searchParams.number1 : "",
    },
    { skip: !(searchParams.number1) }
  );
  const { data: epoBiblioData2 } = useGetEpoBiblioQuery(
    {
      type: type ? type : searchParams.type,
      format: "docdb",
      number: searchParams.number2 !== "" ? searchParams.number2 : "",
    },
    { skip: !(searchParams.number2) }
  );

  const familyBiblio = useSelector((state) => state.family.familyBiblio);
  const familyMembers =
    familyBiblio?.["ops:world-patent-data"]?.["ops:patent-family"]?.[
    "ops:family-member"
    ] || [];
  const member = familyMembers[0] || {};

  const [getPatentBiblio] = useGetPatentBiblioMutation();

  const handleEdit = () => {
    dispatch(setCanTopEdit(!canTopEdit))
    setApiCalled(true)
  }
  const handleCloseEdit = () => {
    dispatch(setCanTopEdit(!canTopEdit))
    setApiCalled(false)
  }
  useEffect(() => {
    const fetchPatentBiblio = async (docNumber) => {
      dispatch(setLoading());
      try {
        const result = await getPatentBiblio({
          criteria: `patentApplicationNumber:${docNumber}`,
          start: 0,
          rows: 100,
        }).unwrap();
        dispatch(setPatentBiblioData(result));
      } catch (err) {
        dispatch(setError(err.message || "Error fetching patent biblio data"));
        console.error("Error fetching patent biblio data:", err);
      }
    };

    if (type === "publication" || type === "application" || type === "patent") {
      if (epoBiblioData) {
        const documentIds =
          epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
          "exchange-document"
          ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"];
        const originalDocNumber = documentIds?.find(
          (doc) => doc["@document-id-type"] === "original"
        )?.["doc-number"]?.["$"];
        if (originalDocNumber) {
          fetchPatentBiblio(originalDocNumber);
        }
      } else if (type === "application" && number) {
        // Handle the case where epoBiblioData is not required for "application" type
        fetchPatentBiblio(number.replace(/^\D+/g, ""));
      }
    } else if (number) {
      fetchPatentBiblio(number.replace(/^\D+/g, ""));
    }
  }, [dispatch, getPatentBiblio, number, epoBiblioData, type]);

  useEffect(() => {
    const fetchPatentBiblio = async (docNumber) => {
      dispatch(setLoading());
      try {
        const result = await getPatentBiblio({
          criteria: `patentApplicationNumber:${docNumber}`,
          start: 0,
          rows: 100,
        }).unwrap();
        dispatch(setPatentBiblioData(result));
      } catch (err) {
        dispatch(setError(err.message || "Error fetching patent biblio data"));
        console.error("Error fetching patent biblio data:", err);
      }
    };
    if (typeof epoBiblioData === "undefined" && epoBiblioData1) {
      if (type === "publication" || type === "application" || type === "patent") {
        if (epoBiblioData1) {
          const documentIds =
            epoBiblioData1?.["ops:world-patent-data"]?.["exchange-documents"]?.[
            "exchange-document"
            ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"];
          const originalDocNumber = documentIds?.find(
            (doc) => doc["@document-id-type"] === "original"
          )?.["doc-number"]?.["$"];
          if (originalDocNumber) {
            fetchPatentBiblio(originalDocNumber);
          }
        } else if (type === "application" && searchParams.number1) {
          // Handle the case where epoBiblioData is not required for "application" type
          fetchPatentBiblio(searchParams.number1.replace(/^\D+/g, ""));
        }
      } else if (searchParams.number1) {
        fetchPatentBiblio(searchParams.number1.replace(/^\D+/g, ""));
      }
    }
  }, [dispatch, getPatentBiblio, searchParams.number1, epoBiblioData1, type]);


  useEffect(() => {
    const fetchPatentBiblio = async (docNumber) => {
      dispatch(setLoading());
      try {
        const result = await getPatentBiblio({
          criteria: `patentApplicationNumber:${docNumber}`,
          start: 0,
          rows: 100,
        }).unwrap();
        dispatch(setPatentBiblioData(result));
      } catch (err) {
        dispatch(setError(err.message || "Error fetching patent biblio data"));
        console.error("Error fetching patent biblio data:", err);
      }
    };
    if (typeof epoBiblioData === "undefined" && typeof epoBiblioData1 === "undefined" && epoBiblioData2) {
      if (type === "publication" || type === "application" || type === "patent") {
        if (epoBiblioData2) {
          const documentIds =
            epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
            "exchange-document"
            ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"];
          const originalDocNumber = documentIds?.find(
            (doc) => doc["@document-id-type"] === "original"
          )?.["doc-number"]?.["$"];
          if (originalDocNumber) {
            fetchPatentBiblio(originalDocNumber);
          }
        } else if (type === "application" && searchParams.number2) {
          // Handle the case where epoBiblioData is not required for "application" type
          fetchPatentBiblio(searchParams.number2.replace(/^\D+/g, ""));
        }
      } else if (searchParams.number2) {
        fetchPatentBiblio(searchParams.number2.replace(/^\D+/g, ""));
      }
    }
  }, [dispatch, getPatentBiblio, searchParams.number2, epoBiblioData2, type]);

  const getValue = (value) =>
    typeof value === "object" && value !== null
      ? value["$"] || "N/A"
      : value || "N/A";
  const getStatusValue = (value) =>
    value && typeof value === "object" && value !== null
      ? value["$"] || "Not Yet Cited"
      : "N/A";
  const responseDoc = patentBiblioData?.response?.docs?.[0] || {};
  const applicantData = epoBiblioData ?
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
    "exchange-document"
    ]?.["bibliographic-data"]?.["parties"]?.["applicants"]?.["applicant"] : epoBiblioData1 ? epoBiblioData1?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]?.["bibliographic-data"]?.["parties"]?.["applicants"]?.["applicant"] : epoBiblioData2 ? epoBiblioData2?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]?.["bibliographic-data"]?.["parties"]?.["applicants"]?.["applicant"] : [];
  const epoSerialNoObject = epoBiblioData ?
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
    "exchange-document"
    ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"][0] :
    epoBiblioData1 ? epoBiblioData1?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"][0] : epoBiblioData2 ? epoBiblioData2?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"][0] : {};
  const epoDocumentArray = epoBiblioData ?
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
    "exchange-document"
    ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"] : epoBiblioData1 ? epoBiblioData1?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"] : epoBiblioData2 ? epoBiblioData2?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"] : [];
  const epoSerialNoNumber = epoSerialNoObject["doc-number"]?.["$"] || "";
  const epoFilingDate =
    epoSerialNoObject["date"]?.["$"] || epoDocumentArray[2]?.["date"]?.["$"]
      ? epoDocumentArray[2]?.["date"]?.["$"]
      : epoDocumentArray[1]?.["date"]?.["$"]
        ? epoDocumentArray[1]?.["date"]?.["$"]
        : "";
  const epoInventionTitle = epoBiblioData ?
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
    "exchange-document"
    ]?.["bibliographic-data"]?.["invention-title"]?.["$"] : epoBiblioData1 ?
      epoBiblioData1?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
      ]?.["bibliographic-data"]?.["invention-title"]?.["$"] : epoBiblioData2 ? epoBiblioData2?.["ops:world-patent-data"]?.["exchange-documents"]?.[
        "exchange-document"
      ]?.["bibliographic-data"]?.["invention-title"]?.["$"] : "";
  const formatDate = (dateString) => {
    if (!dateString) return "";
    return dateString.split("T")[0];
  };
  const formatHyphenDate = (dateStr) => {
    if (!dateStr || dateStr.length !== 8) return "";
    return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(
      6,
      8
    )}`;
  };

  const filingDate = loading
    ? ""
    : formatDate(responseDoc["filingDate"]) || "N/A";

  const attorneyDocketNumber =
    getValue(responseDoc?.applicantFileReference?.[0]) !== "N/A"
      ? getValue(responseDoc?.applicantFileReference?.[0])
      : docketNumber || "N/A";

  const usptoPubNumber = getValue(responseDoc?.patentNumber?.[0])

  useEffect(() => {
    if (searchParams.type === 'application' && usptoPubNumber !== "" && usptoPubNumber !== 'N/A') {
      const usNum = `US${usptoPubNumber}`
      dispatch(setUsPublNum(usNum))
    }
  }, [usptoPubNumber, searchParams.type, dispatch])

  const data = {
    "Serial #":
      radioOption !== "option1"
        ? loading
          ? ""
          : getValue(responseDoc?.patentApplicationNumber?.[0]) !== "N/A"
            ? getValue(responseDoc?.patentApplicationNumber?.[0])
            : epoSerialNoNumber !== ""
              ? epoSerialNoNumber.slice(4)
              : "N/A"
        : "N/A",
    "Filing Date":
      radioOption !== "option1"
        ? filingDate !== "N/A"
          ? filingDate
          : epoFilingDate !== ""
            ? formatHyphenDate(epoFilingDate)
            : "N/A"
        : "N/A",
    "Attorney Docket #":
      radioOption !== "option1"
        ? loading
          ? ""
          : attorneyDocketNumber
        : docketNumber,
    Applicant:
      radioOption !== "option1"
        ? loading
          ? ""
          : getValue(applicantData[0]?.["applicant-name"]?.["name"]?.["$"])
        : "N/A",
    "Title of Invention":
      radioOption !== "option1"
        ? loading
          ? ""
          : getValue(responseDoc?.inventionTitle?.[0]) !== "N/A"
            ? getValue(responseDoc?.inventionTitle?.[0])
            : epoInventionTitle !== ""
              ? epoInventionTitle
              : "N/A"
        : "N/A",
    Status:
      radioOption !== "option1"
        ? loading
          ? ""
          : getStatusValue(responseDoc?.accessLevelCategory?.[0])
        : "Not Yet Cited",
  };
  const topContentData = {
    Id_num: searchParams.number === "" ? docketNumber : searchParams.number,
    userId: userId,
    serial_num: data["Serial #"],
    filing_date: new Date(data["Filing Date"]),
    docket_num: data["Attorney Docket #"],
    applicant_name: data["Applicant"],
    title: data["Title of Invention"],
    status: data["Status"],
  }
  const headerToFieldObject = {
    "Serial #": "serial_num",
    "Attorney Docket #": "docket_num",
    "Filing Date": "filing_date",
    "Applicant": "applicant_name",
    "Title of Invention": "title",
    "Status": "status",
  };
  const handleChange = async (value, key) => {
    if (key === "Attorney Docket #" && value.length > 15) {
      alert("Docket Number cannot be more than 15 characters.")
      return;
    }
    const convertedHeader = headerToFieldObject[key];
    if (convertedHeader === "filing_date") {
      if (isValidDate(value) === false) {
        alert("Please enter a valid date.")
        return;
      }
      const editedDate = new Date(value).toISOString();
      setEditObject({ ...editObject, [convertedHeader]: editedDate })
      if (Object.keys(reduxTopContentData).length > 0) {
        dispatch(setTopContentData({ ...reduxTopContentData, [convertedHeader]: editedDate }))
        return;
      }
      dispatch(setTopContentData({ ...topContentData, [convertedHeader]: editedDate }))
      return;
    }
    setEditObject({ ...editObject, [convertedHeader]: value })
    if (Object.keys(reduxTopContentData).length > 0) {
      dispatch(setTopContentData({ ...reduxTopContentData, [convertedHeader]: value }))
      return;
    }
    dispatch(setTopContentData({ ...topContentData, [convertedHeader]: value }))
  }
  const handleEditContent = async () => {
    if (editObject['docket_num']?.length > 15) {
      alert("Docket Number cannot be more than 15 characters.")
      return;
    }
    await editTopContent({ data: editObject, id: searchParams.number === "" ? docketNumber : searchParams.number, userId: userId })
    dispatch(setCanTopEdit(!canTopEdit))
  }
  const adminData = {
    Id_num: number ? plainSearchNum.replace(/\s+/g, '').replace(/[\/,]/g, '') : "",
    family_mem: number ? plainSearchNum.replace(/\s+/g, '').replace(/[\/,]/g, '') : "",
    serial_num: getValue(responseDoc?.patentApplicationNumber?.[0]) !== "N/A" ? getValue(responseDoc?.patentApplicationNumber?.[0]) : data['Serial #'],
    docket_num: attorneyDocketNumber,
    applicants: getValue(applicantData[0]?.["applicant-name"]?.["name"]?.["$"]),
    title: getValue(responseDoc?.inventionTitle?.[0]),
    status: "PENDING",
    filing_status: "Status",
    filling_date: data?.['Filing Date'],
    userId: userId
  };

  const handleInput = (e, key, value) => {
    const newContent = e.target.innerText;
    if (key === "Attorney Docket #" && newContent.length > 15) {
      alert("Docket Number cannot be more than 15 characters.");
      e.target.innerText = value;
    }
  };

  useEffect(() => {
    const saveData = async () => {
      const dataToSave = [adminData];
      if (
        typeof adminDataList === "object" &&
        !Array.isArray(adminDataList) &&
        radioOption !== "option1"
      ) {
        const updatedAdminData = {
          ...adminDataList,
          ...dataToSave[0],
        };
        const dataToSaveSup = [{ ...dataToSave[0], 'ids_type': "Supplementary IDS", 'curr_date': adminDataList.curr_date, 'filling_date': data?.['Filing Date'] === "N/A" ? adminDataList.curr_date : convertDateToISO(data?.['Filing Date']) }]
        const { error, ...filteredAdminData } = updatedAdminData;
        dispatch(saveAdmin(dataToSaveSup));
        dispatch(setAdminData(filteredAdminData));
      } else if (
        typeof adminDataList === "object" &&
        !Array.isArray(adminDataList) &&
        radioOption === "option1"
      ) {
        const updatedAdminData = {
          ...adminDataList,
          ...dataToSave[0],
        };
        const dataToSaveInit = [{ ...dataToSave[0], 'ids_type': "Initial IDS", 'curr_date': adminDataList.curr_date, 'filling_date': data?.['Filing Date'] === "N/A" ? adminDataList.curr_date : convertDateToISO(data?.['Filing Date']) }]
        const { error, ...filteredAdminData } = updatedAdminData;
        dispatch(saveAdmin(dataToSaveInit));
        dispatch(setAdminData(filteredAdminData));
      }
      else if (popupNewIdsNumber !== "") {
        if (radioOption === "option1") {
          const updatedAdminData = {
            ...adminDataList,
            ...dataToSave[0],
          };
          const dataToSaveInit = [{ ...dataToSave[0], 'ids_type': "Initial IDS", 'curr_date': adminDataList.curr_date, 'filling_date': data?.['Filing Date'] === "N/A" ? adminDataList.curr_date : convertDateToISO(data?.['Filing Date']) }]
          const { error, ...filteredAdminData } = updatedAdminData;
          dispatch(saveAdmin(dataToSaveInit));
          dispatch(setAdminData(filteredAdminData));
          return;
        } else {
          const updatedAdminData = {
            ...adminDataList,
            ...dataToSave[0],
          };
          const dataToSaveSup = [{ ...dataToSave[0], 'ids_type': "Supplementary IDS", 'curr_date': adminDataList.curr_date, 'filling_date': data?.['Filing Date'] === "N/A" ? adminDataList.curr_date : convertDateToISO(data?.['Filing Date']) }]
          const { error, ...filteredAdminData } = updatedAdminData;
          dispatch(saveAdmin(dataToSaveSup));
          dispatch(setAdminData(filteredAdminData));
          return;
        }
      }
      else {
        console.error("adminDataList is not an object:", adminDataList);
      }
    };
    saveData();
  }, [patentBiblioData, epoBiblioData]);
  useEffect(() => {
    const saveTopContentData = async () => {
      dispatch(saveTopContent(topContentData))
      dispatch(setTopContentData({ ...topContentData, filing_date: data['Filing Date'] }))
    }
    if (apiCalled === true) {
      saveTopContentData()
    }
  }, [apiCalled])
  return (
    <div className="card-content">
      {radioOption !== "option1" && loading && <div>Loading...</div>}
      {radioOption !== "option1" && error && <div>Error: {error}</div>}
      {popupNewIdsNumber !== "" && savedTopContentData.length > 0 ? Object.keys(formattedTopContentObject).map((key, index) => (
        <div className="row" key={index}>
          <div className="key">{key}</div>
          <div className={`value ${canTopEdit === true ? 'edit-border mb-1 ps-1' : ''}`}>
            <span contentEditable={canTopEdit === true ? "true" : "false"}
              suppressContentEditableWarning={true} onInput={(e) => handleInput(e, key, formattedTopContentObject[key])} onBlur={(event) => { const updatedValue = event.target.innerText; handleChange(updatedValue, key) }} className={`${canTopEdit === true ? 'edit-span' : ''}`}>{formattedTopContentObject[key]}</span></div>
        </div>
      )) : Object.keys(data).map((key, index) => (
        <div className="row" key={index}>
          <div className="key">{key}</div>
          <div className={`value ${canTopEdit === true ? 'edit-border mb-1 ps-1' : ''}`}>
            <span contentEditable={canTopEdit === true ? "true" : "false"}
              suppressContentEditableWarning={true} onInput={(e) => handleInput(e, key, data[key])} onBlur={(event) => { const updatedValue = event.target.innerText; handleChange(updatedValue, key) }} className={`${canTopEdit === true ? 'edit-span' : ''}`}>{data[key]}</span></div>
        </div>
      ))}
      {canTopEdit !== true ? <img className="top-edit-icon" src={TableEdit} onClick={handleEdit} title="Edit the Fields in this Section" /> : (
        <span className="d-flex flex-row top-edit-icon" >
          <img src={checkBtn} className="edit-btns me-1" title="Save" onClick={handleEditContent} />
          <img src={xBtn} className="edit-btns" title="Close Edit" onClick={handleCloseEdit} />
        </span>
      )}
    </div>
  );
};

export default TopContent;
